import {format} from "date-fns";

const convertDate = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay), 'dd-MM-yyyy')
    }
    return date;
}

const obtainClock = (date) => {
    if(date){
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        let hour = day.getHours()
        let min = day.getMinutes()
        let seconds = day.getSeconds()
        return format(new Date(numberYear, numberMonth, numberDay, hour, min, seconds), 'HH:mm:ss')
    }
}

export {convertDate, obtainClock}