import config from "../settings/config"

const CREATE_PRODUCT_URL_API = `${config.urlServer}/api/company/create-product`

const ProductService = {}

ProductService.createProduct = async (token, formData) => {
    if(token){
        const createProduct = await fetch(CREATE_PRODUCT_URL_API,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: formData
        })
        // console.log(createProduct)
        if (!createProduct.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al crear el producto'
            }
            return response
        }
        let data = await createProduct.json()
        let response = {
            code: data.code,
            companyLogo: data.msg
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

export default ProductService