import React from 'react'
import { Box, Paper, Typography, IconButton, Divider, Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import AccountService from '../../../services/AccountService';
import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';
import UpdateAccountInfo from './Modals/UpdateAccountInfo';
import DeleteAccount from './Modals/DeleteAccount';

const InfoAccount = () => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(true)
    const [openUodateAccount, setOpenUploadAccountInfo] = React.useState(false)
    const [openDeleteAccountModal, setOpenDeleteAccountModal] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [infoAccount, setInfoAccount] = React.useState({
        firstName: 'firstName',
        lastName: 'lastName',
        typeDoc: 'CEDULA_DE_CIUDADANIA',
        numDoc: '1000000000',
        phone: '+57300000000',
        active: true,
        userInfo: {
            username: "Username",
            email: "correo@correo.com",
            type: "User",
        }
    })

    //Functions
    const getInfoAccount = React.useCallback(async (token) => {
        setLoad(true)

        try {
            let getInfoAccountData = await AccountService.getInfoAccount(token)
            if(getInfoAccountData.account){
                setInfoAccount(getInfoAccountData.account)
                setLoad(false)
            }else{
                setError(getInfoAccountData.msg)
                setLoad(false)
            }
        } catch (error) {
            setError(error.message)
            setLoad(false)
        }

    }, [])

    //useEffect
    React.useEffect(() => {
        if(!openUodateAccount){
            void getInfoAccount(token)
        }
    }, [getInfoAccount, token, openUodateAccount])

    if (load) {
        return (
            <>
                <Paper sx={{ height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner />
                    </Box>
                </Paper>
            </>
        )
    } else {
        return (
            <>
                <Paper>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            sx={{
                                width: 120,
                                height: 120,
                                mt: 6
                            }}
                        >
                            <img style={{ width: '100%' }} alt={'logo empresa'} src='/asset/logo_tokomarket_lightblue.png' />
                        </Box>
                        <Box>
                            {
                                error && <Typography>{error}</Typography>
                            }
                        </Box>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography>{infoAccount.userInfo?.username}</Typography>
                            <Typography
                                sx={{
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    color: 'white',
                                    borderRadius: '2rem',
                                    textAlign: 'center',
                                    width: '100%',
                                    mt: 3,
                                    paddingX: 1
                                }}
                            >{infoAccount.userInfo?.type}</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                mt: 3
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    mr: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        mr: 1
                                    }}
                                >
                                    <IconButton
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}
                                    >
                                        <CheckIcon sx={{ color: (theme) => theme.palette.primary.main }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant='h5'>1.23K</Typography>
                                    <Typography variant='p'>Compras</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    mr: 2
                                }}
                            >
                                <Box
                                    sx={{
                                        mr: 1
                                    }}
                                >
                                    <IconButton
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}
                                    >
                                        <CheckIcon sx={{ color: (theme) => theme.palette.primary.main }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant='h5'>1.23K</Typography>
                                    <Typography variant='p'>Compras</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            p: 2,
                            mt: 4,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant={'h5'}>Detalles</Typography>
                        <Divider sx={{ margin: '15px 0' }} />
                        <Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Nombre Completo:</Typography><Typography variant={'p'}>{infoAccount.firstName} {infoAccount.lastName}</Typography>
                            </Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Tipo documento:</Typography><Typography variant={'p'}>{infoAccount.typeDoc}</Typography>
                            </Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Número de documento:</Typography><Typography variant={'p'}>{infoAccount.numDoc}</Typography>
                            </Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Email:</Typography><Typography variant={'p'}>{infoAccount.userInfo?.email}</Typography>
                            </Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Celular:</Typography><Typography variant={'p'}>{infoAccount.phone}</Typography>
                            </Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Estado:</Typography><Typography variant={'p'}>{infoAccount.active ? 'active' : 'Inactivo'}</Typography>
                            </Box>
                            <Box sx={{ my: 1 }}>
                                <Typography variant={'p'} sx={{ fontWeight: 'bold', mr: 2 }}>Role:</Typography><Typography variant={'p'}>{infoAccount.userInfo?.type}</Typography>
                            </Box>

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                                mb: 1
                            }}
                        >
                            <Button variant="contained" size="large" sx={{ mr: 2 }} onClick={() => setOpenUploadAccountInfo(true)}>Editar</Button>
                            <Button variant="outlined" size="large" color="error" onClick={() => setOpenDeleteAccountModal(true)}>Eliminar Cuenta</Button>
                        </Box>
                    </Box>
                </Paper>
                <UpdateAccountInfo openUodateAccount={openUodateAccount} setOpenUploadAccountInfo={setOpenUploadAccountInfo} infoAccount={infoAccount} />
                <DeleteAccount openDeleteAccountModal={openDeleteAccountModal} setOpenDeleteAccountModal={setOpenDeleteAccountModal} infoAccount={infoAccount} />
            </>
        )
    }
}

export default InfoAccount