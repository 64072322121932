import React from 'react'
import { Box, Typography, IconButton, Paper, InputLabel, FormHelperText, FormControl, OutlinedInput, InputAdornment } from '@mui/material'
import useAuth from '../../../hooks/useAuth';
import { toast } from "react-toastify";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordIcon from '@mui/icons-material/Password';
import AccountService from '../../../services/AccountService';

const ChangePassword = () => {
    const { token, logout } = useAuth()
    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setpassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [isMatchPassword, setIsMatchPassword] = React.useState(false)
    const [load, setLoad] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [messageError, setMessageError] = React.useState("La contraseña debe tener al menos 8 caracteres")

    //Functions
    const handleChangePassword = (e) => {
        setError(false)
        setpassword(e.target.value)
    }

    const handleChangeConfirmPassword = (e) => {
        setError(false)
        setConfirmPassword(e.target.value)
        if (password.length > 4 && password === e.target.value) {
            setIsMatchPassword(true)
        } else {
            setIsMatchPassword(false)
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoad(true)
        if (password.length < 7) {
            setError(true)
            setMessageError("La contraseña debe contener al menos 8 caracteres")
            setLoad(false)

            setTimeout(() => {
                setError(false)
                setMessageError("La contraseña debe tener al menos 8 caracteres")
            }, 2000)
        } else {
            try {
                let passwordChanged = await AccountService.changePassword(token, password)
                // console.log(passwordChanged)
                switch (passwordChanged.code) {
                    case '500':
                        setError(true)
                        setMessageError(passwordChanged.msg)
                        setLoad(false)
                        break;
                    case '200':
                        setLoad(false)
                        toast.success("Contraseña cambiada exitosamente", 2000)
                        logout()
                        break;
                    default:
                        setError(true)
                        setMessageError("Error desconocido en el servicio")
                        setLoad(false)
                }
                setTimeout(() => {
                    setError(false)
                    setMessageError("La contraseña debe tener al menos 8 caracteres")
                }, 3000)
                // setpassword("")
                // setConfirmPassword("")
            } catch (error) {
                setMessageError("Hubo un error al intentar cambiar la clave")
                setLoad(false)
                setError(true)
            }
        }
    }

    return (
        <Paper sx={{ pt: 2 }}>
            <Typography sx={{ fontSize: '1.5rem', ml: 2, mb: 1 }}>Cambiar la contraseña de la cuenta</Typography>
            {error ? <Alert sx={{ mb: 2 }} severity="error">{messageError}</Alert> : <Alert sx={{ mb: 2 }} severity="warning">{messageError}</Alert>}
            <Box
                sx={{ width: '95%', ml: '3%' }}
            >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{ width: '50%' }}
                        >
                            <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => handleChangePassword(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contraseña"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Escribe la nueva contraseña</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{ width: '50%' }}
                        >
                            <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirmar contraseña</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-confirm-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => handleChangeConfirmPassword(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirmar contraseña"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Confirme la contraseña</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            pb: 3
                        }}
                    >
                        {
                            isMatchPassword ? (
                                <LoadingButton
                                    loading={load}
                                    loadingPosition="start"
                                    type='submit'
                                    startIcon={<PasswordIcon />}
                                    variant="contained"
                                >
                                    CHANGE PASSWORD
                                </LoadingButton>
                            )
                                :
                                (
                                    <LoadingButton
                                        loading={load}
                                        loadingPosition="start"
                                        type='button'
                                        startIcon={<PasswordIcon />}
                                        variant="contained"
                                        color={'default'}
                                    >
                                        CHANGE PASSWORD
                                    </LoadingButton>
                                )
                        }

                    </Box>
                </form>
            </Box>
        </Paper>
    )
}

export default ChangePassword