import config from "../settings/config"

const BazaarService = {}
const CREATE_COMPANY_API_URL = `${config.urlServer}/api/company/create-company`
const VERIFY_IF_EXIST_COMPANY = `${config.urlServer}/api/company/verify-company`

BazaarService.createCompany = async (token) => {
    if(token){
        const createdCompany = await fetch(CREATE_COMPANY_API_URL,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            }
        })
        if (!createdCompany.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al crear el negocio'
            }
            return response
        }
        let data = await createdCompany.json()
        let response = {
            code: '200',
            company: data.company
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

BazaarService.verifyCompany = async (token) => {
    if(token){
        const verifyCompany = await fetch(VERIFY_IF_EXIST_COMPANY,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            }
        })
        if (!verifyCompany.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al crear el negocio'
            }
            return response
        }
        let data = await verifyCompany.json()
        let response = {
            code: "200",
            verifyCompany: data
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

export default BazaarService