import config from "../settings/config"
const CompanyService = {}

const FETCH_COMPANY_ALL_INFO_API_URL = `${config.urlServer}/api/company/fetch-company-all-info`
const UPDATE_BASIC_DATA_COMPANY_API_URL = `${config.urlServer}/api/company/update-basic-data`
const CHANGE_COMPANY_LOGO_API_URL = `${config.urlServer}/api/company/change-company-logo`
const CHANGE_COMPANY_BANNER_API_URL = `${config.urlServer}/api/company/change-company-banner`
const PUBLISH_COMPANY_API_URL = `${config.urlServer}/api/company/pusblish-company`

CompanyService.fetchCompanyAllInfo = async (token) => {
    if(token){
        const fetchCompanyAllInfo = await fetch(FETCH_COMPANY_ALL_INFO_API_URL,{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            }
        })
        if (!fetchCompanyAllInfo.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al devolver la información del comercio'
            }
            return response
        }
        let data = await fetchCompanyAllInfo.json()
        let response = {
            code: data.code,
            company: data.company
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

CompanyService.updateBasicDataCompany = async (token, companyData) => {
    if(token){
        const updateBasicDataCompany = await fetch(UPDATE_BASIC_DATA_COMPANY_API_URL,{
            method:'PUT',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(companyData)
        })
        if (!updateBasicDataCompany.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al devolver la información del comercio'
            }
            return response
        }
        let data = await updateBasicDataCompany.json()
        let response = {
            code: data.code,
            company: data.company
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

CompanyService.changeCompanyLogo = async(token, image) => {
    if(token){
        const updateCompanyLogo = await fetch(CHANGE_COMPANY_LOGO_API_URL,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: image
        })
        if (!updateCompanyLogo.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al actualizar la imagen del comercio'
            }
            return response
        }
        let data = await updateCompanyLogo.json()
        let response = {
            code: data.code,
            companyLogo: data.updateImage
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

CompanyService.changeCompanyBanner = async(token, image) => {
    if(token){
        const updateCompanyBanner = await fetch(CHANGE_COMPANY_BANNER_API_URL,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: image
        })
        if (!updateCompanyBanner.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al actualizar la imagen del comercio'
            }
            return response
        }
        let data = await updateCompanyBanner.json()
        let response = {
            code: data.code,
            companyLogo: data.updateImage
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

CompanyService.publishCompany = async(token, companyId) => {
    if(token){
        const updateCompany = await fetch(PUBLISH_COMPANY_API_URL,{
            method:'PUT',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({companyId: companyId})
        })
        if (!updateCompany.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al actualizar el comercio'
            }
            return response
        }
        let data = await updateCompany.json()
        let response = {
            code: data.code,
            companyLogo: data
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

export default CompanyService