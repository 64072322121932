import React from 'react'
import { Paper, Typography, Box, Button } from '@mui/material'
import UpdateBillingAddress from './Modals/UpdateBillingAddress'
import useAuth from '../../../hooks/useAuth';
import ColombiaStates from '../../../assets/country/ColombiaStates.json'
import Spinner from '../../custom/Spinner';

//Services
import AccountService from '../../../services/AccountService';

const countries = [
    {label: "COLOMBIA", value:"COLOMBIA"}
]

const BillingAddress = () => {
    //Countries States
    const [depColombia, setDepColombia] = React.useState(ColombiaStates)
    // States
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [openUpdateBillingAddress, setOpenUpdateBillingAddress] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [billingInformation, setBillingInformation] = React.useState({
        companyName: "Company name",
        billingEmail: "correo@correo.com",
        contact: "3162141660",
        country:"COLOMBIA",
        state: "",
        city: "",
        address: "Calle 51 # 27 - 44",
        zip: "050014"
    })

    //functions
    const getBillingInformation = React.useCallback(async() => {
        setLoad(true)
        try {
            let getBillingAccountInformation = await AccountService.getBillingInformation(token)
            if(getBillingAccountInformation.billinfInfo){
                setBillingInformation(getBillingAccountInformation.billinfInfo)
                setLoad(false)
            }else{
                setError(getBillingAccountInformation.msg)
                setLoad(false)
            }
        } catch (error) {
            setError(error.message)
            setLoad(false)
        }
    }, [token])

    //useEffect
    React.useEffect(() => {
        if(!openUpdateBillingAddress){
            void getBillingInformation(token)
        }
    }, [getBillingInformation, token, openUpdateBillingAddress])

    if (load) {
        return (
            <>
                <Paper sx={{ height: '20vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner />
                    </Box>
                </Paper>
            </>
        )
    }else{
        return (
            <>
                <Paper sx={{ pt: 2, mt: 3, pb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '1.5rem', ml: 2, mb: 3 }}>Datos de factura</Typography>
                        <Button onClick={()=> setOpenUpdateBillingAddress(!openUpdateBillingAddress)} variant="contained" size="medium" sx={{ mr: 2, height: '2.5rem' }}>EDIT ADDRESS</Button>
                    </Box>
                    <Box sx={{m:2}}>
                        {
                            error && <Typography>{error}</Typography>
                        }
                    </Box>
                    <Box sx={{ m: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '50%' }}>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Titular:</Typography><Typography>{billingInformation.companyName}</Typography></Box>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Correo electrónico:</Typography><Typography>{billingInformation.billingEmail}</Typography></Box>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Ciudad:</Typography><Typography>{billingInformation.city}</Typography></Box>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Dirección:</Typography><Typography>{billingInformation.address}</Typography></Box>
                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Celular:</Typography><Typography>{billingInformation.contact}</Typography></Box>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>País:</Typography><Typography>{billingInformation.country}</Typography></Box>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Estado:</Typography><Typography>{billingInformation.state}</Typography></Box>
                                <Box sx={{ display: 'flex', mb: 2 }}><Typography sx={{ fontWeight: 'bold', mr: 2 }}>Código Postal:</Typography><Typography>{billingInformation.zip}</Typography></Box>
    
                            </Box>
                        </Box>
                    </Box>
                </Paper>
                <UpdateBillingAddress 
                    openUpdateBillingAddress={openUpdateBillingAddress}
                    setOpenUpdateBillingAddress={setOpenUpdateBillingAddress}
                    billingInformation={billingInformation}
                    countries={countries}
                    depColombia={depColombia}
                    setDepColombia={setDepColombia}
                    token={token}
                />
            </>
        )
    }
}

export default BillingAddress