import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

import InterestItem from './InterestItem';
import { Paper, Typography } from '@mui/material';

const Interests = () => {
    return (
        <Paper sx={{mt:2}}>
            <Typography
                sx={{
                    fontSize: 22,
                    p:2
                }}
                variant='h3'
            >Podría interesarte</Typography>
            <Swiper 
                watchSlidesProgress={true} 
                slidesPerView={2}
                spaceBetween={5}
                breakpoints={{
                    680: {
                      slidesPerView: 3,
                      spaceBetween: 5,
                    },
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 7,
                      spaceBetween: 20,
                    },
                    1333: {
                        slidesPerView: 9,
                        spaceBetween: 20,
                    },
                    1500: {
                        slidesPerView: 9,
                        spaceBetween: 20,
                    },
                }}
            >
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide><SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
                <SwiperSlide>
                    <InterestItem />
                </SwiperSlide>
            </Swiper>
        </Paper>
    )
}

export default Interests