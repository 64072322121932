import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Typography, Alert, Checkbox } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AccountService from '../../../../../services/AccountService';
import useAuth from '../../../../../hooks/useAuth'

const JoinUsStepsTerms = ({ openJoinUsStepsTerms, setOpenJoinUsStepsTerms, setOpenJoinUsStepsWelcome }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [checkbox, setCheckbox] = React.useState(false)
    const [error, setError] = React.useState(null)

    const handleClose = () => {
        setOpenJoinUsStepsTerms(false);
    }

    const submitHandlerTerms = async () => {
        setLoad(true)
        try {
            let updatedAccount = await AccountService.aceptTermsFromCompanyAccount(token, checkbox)
            if(updatedAccount.code === '200'){
                // console.log(updatedAccount)
                setLoad(false)
                handleClose()
                setOpenJoinUsStepsWelcome(true)
            }else{
                setError(updatedAccount.msg)
                setLoad(false)
            }
        } catch (error) {
            console.log(error)
            setError(error.message)
            setLoad(false)
        }
    }

    return (
        <>
            <Dialog
                open={openJoinUsStepsTerms}
                onClose={handleClose}
                aria-labelledby="alert-dialog-delete-modal"
                aria-describedby="alert-dialog-delete-modal"
            >
                <DialogTitle id="alert-dialog-delete-modal-title">
                    {"Registro de comerciante"}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ px: 2 }}>
                        <Typography variant='h3' sx={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center', mb: 3 }}>Ya estás cerca</Typography>
                    </Box>
                    <Box>
                        <Alert severity="warning">
                            <Typography sx={{ fontWeight: 'bold' }}>Por favor acepta los término y condiciones</Typography>
                            <Typography>Acepta los términos y condiciones para poder acceder a toda nuestra funcionalidad como comerciante.</Typography>
                        </Alert>
                    </Box>
                    {
                        error !== null && (
                            <Box>
                                <Alert severity="error">
                                    <Typography sx={{ fontWeight: 'bold' }}>Ha ocurrido un error</Typography>
                                    <Typography>{error}</Typography>
                                    <Typography>Ocurrio un error al actualizar los terminos y condiciones en tu cuenta. Vuelve a intentarlo, si no funciona contáctanos.</Typography>
                                </Alert>
                            </Box>
                        )
                    }
                    <Box sx={{ my: 4, maxHeight: 300, overflowY: 'scroll', p: 3 }}>
                        <Typography variant='h3' sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Lee nuestros términos y condiciones
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                        <Typography variant='p' sx={{ fontSize: 14 }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae vel minima non ipsum! Illum veritatis accusantium error est eum, dolorem commodi, et unde quisquam consequatur corporis excepturi accusamus magni? Deleniti doloribus delectus ex corrupti veniam. Quam nulla mollitia suscipit provident!.
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                        <Checkbox value={checkbox} onChange={(e) => setCheckbox(e.target.checked)} /><Typography sx={{ mt: 1 }}>Estás de acuerdo y aceptas nuestros términos y condiciones.</Typography>
                    </Box>
                    {
                        checkbox && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                                <LoadingButton
                                    loading={load}
                                    onClick={() => submitHandlerTerms()}
                                    loadingPosition="start"
                                    type='button'
                                    startIcon={<DoubleArrowIcon />}
                                    variant="contained"
                                >
                                    Continuar
                                </LoadingButton>
                            </Box>
                        )
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default JoinUsStepsTerms