import React from 'react'
import { Box,Dialog, DialogContent, DialogTitle, Typography, Alert } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {useNavigate} from 'react-router-dom'
import BazaarService from '../../../../../services/BazzarService';
import useAuth from '../../../../../hooks/useAuth'

const JoinUsStepsWelcome = ({openJoinUsStepsWelcome, setOpenJoinUsStepsWelcome}) => {
    const [load, setLoad] = React.useState(false)
    const {token} = useAuth()
    const navigate = useNavigate()
    //Functions
    const handleClose = () => {
        setOpenJoinUsStepsWelcome(false);
    }

    const handleSubmit = async () => {
        setLoad(true)
        try {
            let createdCompany = await BazaarService.createCompany(token)
            setLoad(false)
            handleClose()
            navigate('/account')
        } catch (error) {
            console.log(error)
            setLoad(false)
        }
    }

    return (
        <>
            <Dialog
                open={openJoinUsStepsWelcome}
                onClose={handleClose}
                aria-labelledby="alert-dialog-delete-modal"
                aria-describedby="alert-dialog-delete-modal"
            >
                <DialogTitle id="alert-dialog-delete-modal-title">
                    {"Registro de comerciante"}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ px: 2, pb: 2 }}>
                        <Typography>Inscríbete como comerciante para ofrecer tus productos o servicios.</Typography>
                    </Box>
                    <Box>
                        <Alert severity="info">
                            <Typography sx={{ fontWeight: 'bold' }}>Completaste el proceso de inscripción</Typography>
                            <Typography>Completaste el proceso de inscripción, ahora debes ordenar tu negocio.</Typography>
                        </Alert>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                        <LoadingButton
                            loading={load}
                            onClick={() => handleSubmit()}
                            loadingPosition="start"
                            type='button'
                            startIcon={<PlayCircleOutlineIcon />}
                            variant="contained"
                        >
                            FINALIZAR INSCRIPCIÓN
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default JoinUsStepsWelcome