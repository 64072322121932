import React from 'react'
import useAuth from '../../../hooks/useAuth'
import MainLayout from '../../layouts/mainLayout/MainLayout'
import GlobalNotificationProvider from '../../../hooks/GlobalNotificationProvider'
import { Box, Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';

//Custom Components
import Plan from './Plan'
import UserPurchase from './UserPurchase'
import UserActivity from './UserActivity'
import ChangePassword from './ChangePassword'
import InfoAccount from './InfoAccount'
import CurrentPlan from './CurrentPlan'
import BillingAddress from './BillingAddress'
import UserList from './UserList'

//Icons
import Person2Icon from '@mui/icons-material/Person2';
import HttpsIcon from '@mui/icons-material/Https';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GroupIcon from '@mui/icons-material/Group';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Account = () => {
  const { logout } = useAuth()
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout onLogout={logout}>
      <GlobalNotificationProvider>
        <Box
          sx={{
            width: '100%',
            backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.default.main[100] : theme.palette.background.default,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', sm: '100%', md: '100%', lg: '30%', xl: '30%' }
            }}
          >
            <InfoAccount />
            <Plan />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '100%', md: '100%', lg: '70%', xl: '70%' },
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                <Tab icon={<Person2Icon />} iconPosition="start" label="DATOS BÁSICOS" {...a11yProps(0)} />
                <Tab icon={<HttpsIcon />} iconPosition="start" label="SEGURIDAD" {...a11yProps(1)} />
                <Tab icon={<ReceiptLongIcon />} iconPosition="start" label="BILLING & PLAN" {...a11yProps(2)} />
                <Tab icon={<GroupIcon />} iconPosition="start" label="USERS" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <UserPurchase />
              <UserActivity />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ChangePassword />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <CurrentPlan />
              <BillingAddress />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <UserList />
            </CustomTabPanel>
          </Box>
        </Box>
      </GlobalNotificationProvider>
    </MainLayout>
  )
}

export default Account