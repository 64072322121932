import React from 'react'
import { toast } from "react-toastify";
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import useAuth from '../../../../hooks/useAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

//Custom Components
import Spinner from '../../../custom/Spinner';

//Service
import CompanyService from '../../../../services/CompanyService'

const styles = {
    width: '100%',
}

const ChangeCompanyBanner = ({openUploadCompanyBanner, setOpenUploadCompanyBanner, companyDataInfo}) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    // const [companyDataError, setCompanyDataError] = React.useState(null)
    const [selectedFile, setSelectedFile] = React.useState(companyDataInfo.companyLogo)
    const [imageLoad, setImageLoad] = React.useState(false)
    const uploadForm = React.useRef(null)

    const handleClose = () => {
        setOpenUploadCompanyBanner(false);
        setSelectedFile(null)
    }

    const handleSumbit = async (e) => {
        e.preventDefault()
        setLoad(true)
        try {
            const form = uploadForm.current
            const fileInput = Array.from(form.elements).find(({ name }) => name === 'file')

            const formData = new FormData()
            for (const file of fileInput.files) {
                formData.append('file', file)
            }
            await CompanyService.changeCompanyBanner(token, formData)
            // console.log(updatedCompanyLogo)
            toast.success("Imagen actualizada correctamente", 2000)
            setLoad(false)
            handleClose()
        } catch (error) {
            console.log(error)
            toast.error("Hubo un error al intentar actualizar", 2000)
            setLoad(false)
        }
    }

    const handleInputFile = (e) => {
        const [file] = e.target.files
        const imgSize8Mb = 8 * 1024 * 1024
        const isValidSize = file.size < imgSize8Mb
        const isNameOfOneImageRegEx = /.(jpg)|.(png)|.(jpeg)|.(webp)$/i
        const isValidType = isNameOfOneImageRegEx.test(file.name)

        if (!isValidSize) {
            e.target.value = ''
            return toast.error('El archivo es demasiado pesado, máximo 8mb', { autoClose: 2000 })
        }
        if (!isValidType) {
            e.target.value = ''
            return toast.error('Sólo se admiten imágenes', { autoClose: 2000 })
        }

        const reader = new FileReader()
        reader.onloadstart = () => {
            setImageLoad(true)
        }
        reader.onloadend = () => {
            // console.log(reader.result)
            setImageLoad(false)
            setSelectedFile(reader.result)
        }
        reader.readAsDataURL(file)

        // handleFormChange()
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={openUploadCompanyBanner}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {"Actualizar imagen de comercio"}
            </DialogTitle>
            <DialogContent>
                {
                    imageLoad ? (<Box
                        sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Spinner />
                    </Box>)
                        :
                        (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItem: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '20vw',
                                        height: "40vh",
                                        overflow: 'hidden',
                                        backgroundColor: 'white',
                                        padding: 1,
                                        borderRadius: 2,
                                        top: 210,
                                        left: 30,
                                        cursor: 'pointer',
                                        zIndex: 10
                                    }}
                                >
                                    <img style={styles} src={selectedFile || companyDataInfo.companyBanner} alt='CompanyInfoImageBanner' />
                                </Box>
                            </Box>
                        )
                }

                <Box ref={uploadForm} component="form" onSubmit={handleSumbit} sx={{ p: 2 }}>
                    <input accept='image/*' type='file' name='file' onChange={(e) => handleInputFile(e)} />

                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mt: 3 }}>
                        <Button onClick={handleClose} variant="contained" size="medium" color={'secondary'}>Cancelar</Button>
                        <LoadingButton
                            loading={load}
                            loadingPosition="start"
                            type='submit'
                            startIcon={<SystemUpdateAltIcon />}
                            variant="contained"
                        >
                            ACTUALIZAR
                        </LoadingButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ChangeCompanyBanner