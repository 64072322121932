import { useLocation, Navigate } from "react-router-dom"
import useAuth from "./useAuth"

const RequireAuth = ({children}) => {
    const {token, setToken} = useAuth()
    let location = useLocation()

    if(!token){
        return <Navigate to="/" state={{ from: location }} replace />;
    }else{
        if(token !== "undefined"){
            return children
        }else{
            setToken(null)
            localStorage.clear()
            return <Navigate to="/" state={{ from: location }} replace />
        }
    }
}

export default RequireAuth