import React from 'react'
import { toast } from "react-toastify";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText, TextField, MenuItem, Typography, Grid } from '@mui/material'
import useAuth from '../../../../hooks/useAuth';
import LoadingButton from '@mui/lab/LoadingButton';
// import BusinessIcon from '@mui/icons-material/Business';
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
// import EmailIcon from '@mui/icons-material/Email';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import InventoryIcon from '@mui/icons-material/Inventory';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

//Custom Components
import Spinner from '../../../custom/Spinner';
import ProductByColors from '../custom/ProductByColors';
import ProductBySizes from '../custom/ProductBySizes';
import ProductByImagesAndColors from '../custom/ProductByImagesAndColors';
import ProductByImagesColorsAndSizes from '../custom/ProductByImagesColorsAndSizes';
import ProductService from '../../../../services/ProductService';

//Service
// import CompanyService from '../../../../services/CompanyService'

const ProductType = [
    { label: "", value: "" },
    { label: "STOCK BASADO EN COLORES", value: "STOCK BASADO EN COLORES" },
    { label: "STOCK BASADO EN TAMAÑOS", value: "STOCK BASADO EN TAMAÑOS" },
    { label: "STOCK BASADO EN IMAGENES Y COLORES", value: "STOCK BASADO EN IMAGENES Y COLORES" },
    { label: "STOCK BASADO EN IMAGENES COLORES Y TAMAÑOS", value: "STOCK BASADO EN IMAGENES COLORES Y TAMAÑOS" },
    // { label: "PERSONALIZADO", value: "PERSONALIZADO" },
]

const StaticProductDetaildata = {
    productQty: "",
    productColor: [],
    productSize: "",
    productImages: []
}

const StaticProductData = {
    productImageFavorite: "",
    productName: "",
    productDescription: "",
    productPrice: 0,
    productType: "",
    productDetails: [],
    productFeatures: [],
    productReserved: true,
    productShipping: true,
}

const AddProductToCommerce = ({ openAddNewProduct, setOpenAddNewProduct, companyDataInfo }) => {
    //States
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [selectedImage, setSelectedImage] = React.useState(null)
    const [companyDataError, setCompanyDataError] = React.useState(null)
    const [detailsProducts, setDetailsProducts] = React.useState([])
    // const [featuresProducts, setFeaturesPorducts] = React.useState([])
    const [productData, setProductData] = React.useState(StaticProductData)

    //functions
    const handleClose = () => {
        setOpenAddNewProduct(false);
        setProductData({
            productImageFavorite: "",
            productName: "",
            productDescription: "",
            productPrice: "",
            productType: "",
            productDetails: [],
            productFeatures: []
        })
    }

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setProductData(company => {
            return {
                ...company,
                [name]: value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoad(true)
        let data = productData
        // let newdata = { ...data, productDetails: detailsProducts }
        // console.log(newdata)

        const formData = new FormData()
        formData.append("productName", data.productName)
        formData.append("productDescription", data.productDescription)
        formData.append("productPrice", data.productPrice)
        formData.append("productType", data.productType)
        formData.append("productReserved", data.productReserved)
        formData.append("productShipping", data.productShipping)
        formData.append("productImageFavorite", selectedImage)

        try {
            const createProduct = await ProductService.createProduct(token, formData)
            // console.log(createProduct)
            if (createProduct.code !== '200') {
                toast.error("Hubo un error al crear el producto", { autoClose: 2000 })
                setLoad(false)
            } else {
                setProductData(StaticProductData)
                handleClose()
                toast.success("Producto creado correctamente", { autoClose: 2000 })
                setLoad(false)
            }
        } catch (error) {
            console.log(error)
            toast.error("Hubo un error al crear el producto", { autoClose: 2000 })
            setCompanyDataError("Hubo un error al crear el producto")
            setLoad(false)
        }

    }

    const chargeToImage = () => {
        const image = document.getElementById("feature-product-image")
        image.click()
    }

    const handleInputFile = (e) => {
        const [file] = e.target.files
        const imgSize5Mb = 8 * 1024 * 1024
        const isValidSize = file.size < imgSize5Mb
        const isNameOfOneImageRegEx = /.(jpe?g|gif|png|webp)$/i
        const isValidType = isNameOfOneImageRegEx.test(file.name)

        if (!isValidSize) {
            e.target.value = ''
            return toast.error('La imagen es demasido pesada, máximo 5mb', { autoClose: 2000 })
        }
        if (!isValidType) {
            e.target.value = ''
            return toast.error('La imagen solo puede tener formato .jpg, .png, .jpeg, .gif, .webp', { autoClose: 2000 })
        }

        const reader = new FileReader()
        reader.onloadstart = () => {
            setLoad(true)
        }
        reader.onloadend = () => {
            // console.log(reader.result)
            setLoad(false)
            setSelectedImage(reader.result)
        }
        reader.readAsDataURL(file)

        // const files = e.target.files;
        // const fileList = Array.from(files);

        // let isValid = true;

        // let message = {}


        // // Validar cada archivo
        // fileList.forEach((file) => {
        //     // Verificar si el archivo no es una imagen
        //     if (!file.type.startsWith('image/')) {
        //         isValid = false;
        //         message = { code: "fileType", msg: "Sólo se admiten imágenes" }
        //     }

        //     // Verificar si el tamaño del archivo excede los 8MB
        //     if (file.size > 8 * 1024 * 1024) {
        //         isValid = false;
        //         message = { code: "fileSize", msg: "Solo se admiten archivos de máximo 8mb" }
        //     }
        // });

        // if (isValid) {
        //     setProductData(product => {
        //         return {
        //             ...product,
        //             productImageFavorite: [...fileList]
        //         }
        //     });
        // } else {
        //     // Mostrar mensaje de error

        //     if (message.code && message.code === "fileType") {
        //         toast.error(message.msg, { autoClose: 3000 })
        //     }

        //     if (message.code && message.code === "fileSize") {
        //         toast.error(message.msg, { autoClose: 3000 })
        //     }
        // }
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openAddNewProduct}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Crear producto nuevo"}
                </DialogTitle>
                <DialogContent>
                    {
                        companyDataError && (
                            <Box
                                sx={{
                                    p: 2
                                }}
                            >
                                <Typography
                                    variant='p'
                                    sx={{
                                        color: '#FE0000',
                                        fontWeight: 'bold'
                                    }}
                                >{companyDataError}</Typography>
                            </Box>
                        )
                    }
                    {
                        load ? (
                            <>
                                <Box
                                    sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <Spinner />
                                </Box>
                            </>
                        ) :
                            (
                                <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box sx={{ width: '100%', mt: 2 }}>
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        fontSize: 18,
                                                        color: '#745856',
                                                        mb: 1,
                                                        pl: 1
                                                    }}
                                                >Imagen destacada</Typography>
                                                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3, cursor: 'pointer' }}>
                                                <img
                                                    style={{ width: 300, height: 300, p: 2, bgcolor: 'red' }}
                                                    alt="Imagen de muestra para crea productos"
                                                    src={selectedImage || "/asset/images/box.png"}
                                                    onClick={chargeToImage}
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    width: '90%',
                                                    display: 'none',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <input
                                                    id="feature-product-image"
                                                    size="medium"
                                                    type='file'
                                                    accept='image/*'
                                                    name='file'
                                                    onChange={(e) => handleInputFile(e)}
                                                />
                                            </Box>


                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-names">Nombre del producto</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-names"
                                                    type={'text'}
                                                    name={'productName'}
                                                    value={`${productData?.productName}`}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <InventoryIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Nombre del producto"
                                                />
                                                <FormHelperText id="outlined-weight-helper-text">Escribe el nombre del producto</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl sx={{ width: '100%', mt: 2 }} variant="outlined">
                                                <InputLabel htmlFor="commerce-product-descrption">Descripción del producto</InputLabel>
                                                <OutlinedInput
                                                    id="commerce-product-descrption"
                                                    type={'text'}
                                                    multiline
                                                    rows={4}
                                                    name={'productDescription'}
                                                    value={`${productData?.productDescription}`}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    label="Descripción del producto"
                                                />
                                                <FormHelperText id="outlined-weight-helper-text">Escribe la descripción del producto</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl sx={{ width: '100%', mt: 2 }} variant="outlined">
                                                <InputLabel htmlFor="commerce-product-price">Precio del producto</InputLabel>
                                                <OutlinedInput
                                                    id="commerce-product-price"
                                                    type={'text'}
                                                    name={'productPrice'}
                                                    value={`${productData?.productPrice}`}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <PriceChangeIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Precio del producto"
                                                />
                                                <FormHelperText id="outlined-weight-helper-text">Indica el valor de venta del producto</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ width: '100%', mt: 2 }}>
                                                <TextField
                                                    id="commerce-product-type"
                                                    select
                                                    size="medium"
                                                    label="Tipo de producto"
                                                    name={'productType'}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    value={productData?.productType}
                                                    defaultValue={productData?.productType}
                                                    helperText="Selecciona el tipo de producto"
                                                    sx={{ width: '100%' }}
                                                >
                                                    {ProductType.map((option, index) => (
                                                        <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {/* <Box sx={{ width: '100%', mt: 2 }}>
                                                <Typography
                                                    variant='h5'
                                                    sx={{
                                                        fontSize: 18,
                                                        color: '#745856',
                                                        mb: 1,
                                                        pl: 1
                                                    }}
                                                >Agregar fotos y detalles según el tipo</Typography>
                                                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                                            </Box> */}

                                            {
                                                productData.productType !== "" && productData.productType === "STOCK BASADO EN COLORES" && (
                                                    <ProductByColors
                                                        productData={productData}
                                                        StaticProductDetaildata={StaticProductDetaildata}
                                                        detailsProducts={detailsProducts}
                                                        setDetailsProducts={setDetailsProducts}
                                                    />
                                                )
                                            }

                                            {
                                                productData.productType !== "" && productData.productType === "STOCK BASADO EN TAMAÑOS" && (
                                                    <ProductBySizes
                                                        productData={productData}
                                                        StaticProductDetaildata={StaticProductDetaildata}
                                                        detailsProducts={detailsProducts}
                                                        setDetailsProducts={setDetailsProducts}
                                                    />
                                                )
                                            }

                                            {
                                                productData.productType !== "" && productData.productType === "STOCK BASADO EN IMAGENES Y COLORES" && (
                                                    <ProductByImagesAndColors
                                                        StaticProductDetaildata={StaticProductDetaildata}
                                                        detailsProducts={detailsProducts}
                                                        setDetailsProducts={setDetailsProducts}
                                                    />
                                                )
                                            }

                                            {
                                                productData.productType !== "" && productData.productType === "STOCK BASADO EN IMAGENES COLORES Y TAMAÑOS" && (
                                                    <ProductByImagesColorsAndSizes
                                                        StaticProductDetaildata={StaticProductDetaildata}
                                                        detailsProducts={detailsProducts}
                                                        setDetailsProducts={setDetailsProducts}
                                                    />
                                                )
                                            }

                                        </Grid>
                                    </Grid>

                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', mt: 3 }}>
                                        <Button onClick={handleClose} variant="contained" size="medium" color={'secondary'}>Cancelar</Button>
                                        <LoadingButton
                                            loading={load}
                                            loadingPosition="start"
                                            type='submit'
                                            startIcon={<SystemUpdateAltIcon />}
                                            variant="contained"
                                            sx={{ ml: 2 }}
                                        >
                                            CREAR PRODUCTO
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            )
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddProductToCommerce