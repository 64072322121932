import React from 'react'
import { toast } from "react-toastify";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText, TextField, MenuItem } from '@mui/material'
import AccountService from '../../../../services/AccountService';
import useAuth from '../../../../hooks/useAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import validateValuesForms from '../../../../utils/validateValuesForms'

const TypeDoc = [
    { label: 'Seleccione una opción', value: '' },
    { label: 'Cédula de ciudadanía', value: 'CEDULA_DE_CIUDADANIA' },
    { label: 'Tarjeta de identidad', value: 'TARJETA_DE_IDENTIDAD' },
    { label: 'Cédula de extrangería', value: 'CEDULA_DE_EXTRANJERIA' },
    { label: 'Pasaporte', value: 'PASAPORTE' },
]

const status = [
    { label: 'Seleccione una opción', value: '' },
    { label: 'Activo', value: true },
    { label: 'Inactivo', value: false },
]

const UpdateAccountInfo = ({ openUodateAccount, setOpenUploadAccountInfo, infoAccount }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [account, setAccount] = React.useState(infoAccount)
    const [errors, setErrors] = React.useState({});

    const handleClose = () => {
        setOpenUploadAccountInfo(false);
    }

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setAccount(account => {
            return {
                ...account,
                [name]: value
            }
        })
    }

    const handleSumbit = async (e) => {
        e.preventDefault()
        setLoad(true)
        const newErrors = {};

        if (account.firstName && account.firstName !== "") {
            let isvalidate = validateValuesForms("alf25", account.firstName)
            if (!isvalidate) {
                newErrors.firstName = 'Los nombres que ingresaste no son válidos';
            }
        }

        if (account.lastName && account.lastName !== "") {
            let isvalidate = validateValuesForms("alf25", account.lastName)
            if (!isvalidate) {
                newErrors.lastName = 'Los apellidos que ingresaste no son válidos';
            }
        }

        if (account.numDoc && account.numDoc !== "") {
            let isvalidate = validateValuesForms("document", JSON.stringify(account.numDoc))
            if (!isvalidate) {
                newErrors.numDoc = 'El número de documento no es válido';
            }
        }

        if (account.phone && account.phone !== "") {
            let isvalidate = validateValuesForms("cellphone", account.phone)
            if (!isvalidate) {
                newErrors.phone = 'El teléfono celular no es válido';
            }
        }

        //Validates Empty
        if (account.firstName === "") newErrors.firstName = 'Debes ingresar los nombre, es un campo requerido';
        if (account.lastName === "") newErrors.lastName = 'Debes ingresar los apellidos, es un campo requerido';
        if (account.typeDoc === "") newErrors.typeDoc = 'El tipo de documento es requerido';
        if (account.numDoc === "") newErrors.numDoc = 'Debes ingresar el número de documento, es un campo requerido';
        if (account.phone === "") newErrors.phone = 'Debes ingresar el número de celular, es un campo requerido';
        if (account.active === "") newErrors.active = 'El estado de la cuenta es requerido';

        if (Object.keys(newErrors).length === 0) {
            try {
                let updatedInfo = await AccountService.updateInfoAccount(token, account)
                switch (updatedInfo.code) {
                    case '500':
                        console.log(updatedInfo.msg)
                        toast.error("Hubo un error al intentar actualizar", 2000)
                        setLoad(false)
                        break;
                    case '200':
                        toast.success("Se han actualizado los datos correctamente", 2000)
                        setOpenUploadAccountInfo(false)
                        setLoad(false)
                        break;
                    default:
                        toast.error("code undefined, el código a validar no existe", 2000)
                        setLoad(false)
                }
            } catch (error) {
                console.log(error)
                toast.error("Hubo un error al intentar actualizar", 2000)
                setLoad(false)
            }
        } else {
            setLoad(false)
            setErrors(newErrors);
            setTimeout(() => {
                setErrors({});
            }, 5000);
        }

    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={openUodateAccount}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Actualizar información de la cuenta"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSumbit} sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection:'column' }}>
                            <Box sx={{ width: '100%', mr: 3 }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-names">Nombres</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-names"
                                        type={'text'}
                                        name={'firstName'}
                                        value={`${account.firstName}`}
                                        onChange={(e) => inputChangeHandler(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Nombres"
                                        error={!!errors.firstName}
                                    />
                                    <FormHelperText error={!!errors.firstName} id="outlined-weight-helper-text">{errors.firstName ? errors.firstName : "Escribe los nombres"}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-lastnames">Apellidos</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-lastnames"
                                        type={'text'}
                                        value={`${account.lastName}`}
                                        name={'lastName'}
                                        onChange={(e) => inputChangeHandler(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Apellidos"
                                        error={!!errors.lastName}
                                    />
                                    <FormHelperText error={!!errors.lastName} id="outlined-weight-helper-text">{errors.lastName ? errors.lastName : "Escribe los apellidos"}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection:'column' }}>
                            <Box sx={{ width: '100%', mr: 3 }}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="medium"
                                    label="Tipo de documento"
                                    name={'typeDoc'}
                                    onChange={(e) => inputChangeHandler(e)}
                                    value={account.typeDoc}
                                    defaultValue={account.typeDoc}
                                    error={!!errors.typeDoc}
                                    helperText={errors.typeDoc ? errors.typeDoc : "Selecciona el tipo de documento"}
                                    sx={{ m: 1, width: '100%' }}
                                >
                                    {TypeDoc.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-lastnames">Número de documento</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-lastnames"
                                        type={'text'}
                                        name={'numDoc'}
                                        value={`${account.numDoc}`}
                                        onChange={(e) => inputChangeHandler(e)}
                                        error={!!errors.numDoc}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <AssignmentIndIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Número de documento"
                                    />
                                    <FormHelperText error={!!errors.numDoc} id="outlined-weight-helper-text">{errors.numDoc ? errors.numDoc : "Escribe el número de documento"}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection:'column' }}>
                            <Box sx={{ width: '100%', mr: 3 }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-lastnames">Número de celular</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-lastnames"
                                        type={'text'}
                                        value={`${account.phone}`}
                                        name={'phone'}
                                        onChange={(e) => inputChangeHandler(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <PhoneAndroidIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Número de celular"
                                        error={!!errors.phone}
                                    />
                                    <FormHelperText error={!!errors.phone} id="outlined-weight-helper-text">{errors.phone ? errors.phone : "Escribe el número de celular"}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="medium"
                                    label="Estado"
                                    name={'active'}
                                    onChange={(e) => inputChangeHandler(e)}
                                    value={account.active}
                                    defaultValue={account.active}
                                    error={!!errors.active}
                                    helperText={errors.active ? errors.active : "Indique el estado de la cuenta"}
                                    sx={{ m: 1, width: '100%' }}
                                >
                                    {status.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', mt: 3 }}>
                            <Button onClick={handleClose} variant="contained" size="medium" color={'secondary'} sx={{mr:2}}>Cancelar</Button>
                            <LoadingButton
                                loading={load}
                                loadingPosition="start"
                                type='submit'
                                startIcon={<SystemUpdateAltIcon />}
                                variant="contained"
                            >
                                ACTUALIZAR
                            </LoadingButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default UpdateAccountInfo