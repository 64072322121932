import config from "../settings/config"

const LOGIN_URL_API = `${config.urlServer}/api/auth/signin`
const LoginService = {}

LoginService.signIn = async(user) => {
    if(user){
        let responseUser = await fetch(LOGIN_URL_API, {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(user)
        })
        .then(response => response.json())
        return responseUser
    }else{
        let response = {
            error: "Error500",
            msg: "Hubo un error al iniciar sesión"
        }
        return response
    }
}

export default LoginService