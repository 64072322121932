import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArticleIcon from "@mui/icons-material/Article";
import StorefrontIcon from '@mui/icons-material/Storefront';
import BusinessIcon from '@mui/icons-material/Business';
import useAuth from "../../../hooks/useAuth";
// import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.default.main[900],
            color: theme.palette.secondary.main,
            "& .MuiListItemIcon-root, .MuiIconButton-root": {
                color: "inherit"
            },
            iconColorPrimary: theme.palette.secondary.main,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const MainLayout = ({ open, onToggleDrawer }) => {
    const { typeUser } = useAuth()
    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={() => onToggleDrawer()}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Box component={Link} to={'/'} sx={{
                width: "100%",
                height: () => open ? 190 : 110,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                transition: "0.3s ease"
            }}>
                <Avatar
                    src='/asset/logo_tokomarket_white.png'
                    sx={{
                        width: () => open ? 100 : 35,
                        height: () => open ? 100 : 35,
                        marginBottom: 1,
                        transition: "0.3s ease",
                    }}
                />
                <Typography color={"white"} sx={{ opacity: () => open ? 1 : 0, }}> </Typography>
            </Box>
            <List component="nav">
                <ListItemButton component={Link} to={'/'}>
                    <ListItemIcon>
                        <StorefrontIcon sx={{ ml: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Bazzar" />
                </ListItemButton>
                <ListItemButton component={Link} to={'/account'}>
                    <ListItemIcon>
                        <HomeIcon sx={{ ml: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Cuenta" />
                </ListItemButton>
                {
                    typeUser && typeUser === "Company" && (
                        <ListItemButton component={Link} to={'/company'}>
                            <ListItemIcon>
                                <BusinessIcon sx={{ ml: 1 }} />
                            </ListItemIcon>
                            <ListItemText primary="Compañia" />
                        </ListItemButton>
                    )
                }
                <ListItemButton component={Link} to={'/references'}>
                    <ListItemIcon>
                        <BusinessCenterIcon sx={{ ml: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Referencias" />
                </ListItemButton>
                <ListItemButton component={Link} to={'/invoices'}>
                    <ListItemIcon>
                        <ArticleIcon sx={{ ml: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Remisiones" />
                </ListItemButton>

                {/* <ListItemButton sx={{position: "relative", top: 120}} onClick={onLogout}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Salir"/>
                </ListItemButton> */}
            </List>
        </Drawer>
    );
}

export default MainLayout;