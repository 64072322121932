import React from 'react'
import { Box, Typography } from '@mui/material'
import {convertDate, obtainClock} from '../../../helpers/utils'

const CompanyActivityItem = () => {
    return (
        <Box
            sx={{
                width: '100%',
                boxSizing:'content-box'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    paddingBottom: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '5%',
                        // backgroundColor:'red'
                    }}
                >
                    <Box
                        component={'div'}
                        sx={{
                            width: '0.8rem',
                            height: '0.8rem',
                            backgroundColor: (theme) => theme.palette.orange.main[500],
                            borderRadius: '50%',
                            mb: 1
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: '0.2rem',
                            height: '3.2rem',
                            backgroundColor: (theme) => theme.palette.default.main[200]
                        }}
                    >
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: {xs:'85%', sm: '85%', md: '85%', lg: '85%', xl:'85%'},
                        // backgroundColor:'blue'
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.default.main[600],
                            fontWeight: 'bold'
                        }}
                    >{'Compra de usuario'}</Typography>
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.default.main[400]
                        }}
                    >{'Se realizó la reserva de servicio'} a las {obtainClock('2023/05/22 14:05:05')}</Typography>
                </Box>
                <Box
                    sx={{
                        width: '10%',
                        // backgroundColor:'green'
                    }}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.default.main[600]
                        }}
                    >{convertDate("05/22/2023")}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default CompanyActivityItem