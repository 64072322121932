import React from 'react'
import { toast } from "react-toastify";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText, TextField, MenuItem, Typography } from '@mui/material'
import useAuth from '../../../../hooks/useAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import EmailIcon from '@mui/icons-material/Email';
import validateValuesForms from '../../../../utils/validateValuesForms';

//Custom Components
import Spinner from '../../../custom/Spinner';

//Service
import CompanyService from '../../../../services/CompanyService'

const CompanyType = [
    { label: 'Seleccione una opción', value: '' },
    { label: 'PRODUCTOS', value: 'PRODUCTS' },
    { label: 'SERVICIOS', value: 'SERVICES' },
    { label: 'MIXTO', value: 'ALL' },
]

const Country = [
    { label: 'Seleccione una opción', value: '' },
    { label: 'COLOMBIA', value: 'COLOMBIA' },
]

const UpdateCompanyInfo = ({ openUpdateCompanyInfo, setOpenUpdateCompanyInfo, companyDataInfo }) => {
    //States
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [companyData, setCompanyData] = React.useState(companyDataInfo)
    const [companyDataError, setCompanyDataError] = React.useState(null)
    const [errors, setErrors] = React.useState({});

    const handleClose = () => {
        setOpenUpdateCompanyInfo(false);
    }

    // functions

    const fecthCompanyAllInfo = React.useCallback(async () => {
        setLoad(true)
        try {
            let fecthCompanyAllInfo = await CompanyService.fetchCompanyAllInfo(token)
            setCompanyData(fecthCompanyAllInfo.company)
            // console.log(fecthCompanyAllInfo)
            setLoad(false)
            return;
        } catch (error) {
            console.log(error)
            setCompanyDataError("Hubo un error al traer la información")
            setLoad(false)
            setTimeout(() => {
                setCompanyDataError(null)
            }, 10000)
            return;
        }
    }, [token])

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setCompanyData(company => {
            return {
                ...company,
                [name]: value
            }
        })
    }

    const handleSumbit = async (e) => {
        e.preventDefault()
        setLoad(true)
        const newErrors = {};
        // console.log(companyData)

        if (companyData.companyName && companyData.companyName !== "") {
            let isvalidate = validateValuesForms("alf25", companyData.companyName)
            if (!isvalidate) {
                newErrors.companyName = 'El nombre del comercio no es válido';
            }
        }

        if (companyData.companyType === "") {
            newErrors.companyType = 'Debes seleccionar un tipo de comercio';
        }

        if (companyData.companyCountry === "") {
            newErrors.companyCountry = 'Debes seleccionar en dónde esta ubicado el comercio';
        }

        if (companyData.companyContact && companyData.companyContact !== "") {
            let isvalidate = validateValuesForms("cellphone", companyData.companyContact)
            if (!isvalidate) {
                newErrors.companyContact = 'El teléfono celular no es válido';
            }
        }

        if (companyData.companyEmail && companyData.companyEmail !== "") {
            let isvalidate = validateValuesForms("email", companyData.companyEmail)
            if (!isvalidate) {
                newErrors.companyEmail = 'El correo electrónico debe escribirse con el formato correcto';
            }
        }

        //Validates Empty
        if(companyData.companyName === "") newErrors.companyName = 'Debes especificar un nombre del comercio, es un campo requerido';
        if(companyData.companyContact === "") newErrors.companyContact = 'Debes especificar un número de contacto, es un campo requerido';
        if(companyData.companyEmail === "") newErrors.companyEmail = 'Debes especificar un email, es un campo requerido';

        if (Object.keys(newErrors).length === 0) {
            try {
                let updatedInfo = await CompanyService.updateBasicDataCompany(token, companyData)
                // console.log(updatedInfo)
                switch (updatedInfo.code) {
                    case '500':
                        toast.error("Hubo un error al intentar actualizar", 2000)
                        setLoad(false)
                        break;
                    case '200':
                        toast.success("Se han actualizado los datos correctamente", 2000)
                        // setOpenUploadAccountInfo(false)
                        handleClose()
                        setLoad(false)
                        break;
                    default:
                        toast.error("code undefined, el código a validar no existe", 2000)
                        setLoad(false)
                }

            } catch (error) {
                console.log(error)
                toast.error("Hubo un error al intentar actualizar", 2000)
                setLoad(false)
            }
        } else {
            setLoad(false)
            setErrors(newErrors);
            setTimeout(() => {
                setErrors({});
            }, 5000);
        }

    }

    //Effects
    React.useEffect(() => {
        void fecthCompanyAllInfo()

    }, [fecthCompanyAllInfo])

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={openUpdateCompanyInfo}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Actualizar información del Comercio"}
                </DialogTitle>
                <DialogContent>
                    {
                        companyDataError && (
                            <Box
                                sx={{
                                    p: 2
                                }}
                            >
                                <Typography
                                    variant='p'
                                    sx={{
                                        color: '#FE0000',
                                        fontWeight: 'bold'
                                    }}
                                >{companyDataError}</Typography>
                            </Box>
                        )
                    }
                    {
                        load ? (
                            <>
                                <Box
                                    sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <Spinner />
                                </Box>
                            </>
                        ) :
                            (
                                <Box component="form" onSubmit={handleSumbit} sx={{ p: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection:'column' }}>
                                        <Box sx={{ width: '100%', mr: 3 }}>
                                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-names">Nombre de la compañía</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-names"
                                                    type={'text'}
                                                    name={'companyName'}
                                                    value={`${companyData?.companyName}`}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    error={!!errors.companyName}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <BusinessIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Nombre de la compañía"
                                                />
                                                <FormHelperText error={!!errors.companyName} id="outlined-weight-helper-text">{errors.companyName ? errors.companyName : "Escribe el nombre de la compañía"}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ width: '100%' }}>
                                            <TextField
                                                id="outlined-select-company-type"
                                                select
                                                size="medium"
                                                label="Tipo de compañía"
                                                name={'companyType'}
                                                onChange={(e) => inputChangeHandler(e)}
                                                value={companyData?.companyType}
                                                defaultValue={companyData?.companyType}
                                                helperText={errors.companyType ? errors.companyType : "Selecciona el tipo de compañía"}
                                                error={!!errors.companyType}
                                                sx={{ m: 1, width: '100%' }}
                                            >
                                                {CompanyType.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection:'column' }}>
                                        <Box sx={{ width: '100%' }}>
                                            <TextField
                                                id="outlined-select-country"
                                                select
                                                size="medium"
                                                label="Ubicación del comercio"
                                                name={'companyCountry'}
                                                onChange={(e) => inputChangeHandler(e)}
                                                value={companyData?.companyCountry}
                                                defaultValue={companyData?.companyCountry}
                                                error={!!errors.companyCountry}
                                                helperText={errors.companyCountry ? errors.companyCountry : "Selecciona el tipo de documento"}
                                                sx={{ m: 1, width: '100%' }}
                                            >
                                                {Country.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                        <Box sx={{ width: '100%' }}>
                                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-contact">Número de contacto</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-contact"
                                                    type={'text'}
                                                    name={'companyContact'}
                                                    value={`${companyData?.companyContact}`}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    error={!!errors.companyContact}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <PhoneAndroidIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Número de contacto"
                                                />
                                                <FormHelperText error={!!errors.companyContact} id="outlined-contact-helper-text">{errors.companyContact ? errors.companyContact : "Escriba el número de contacto con los 10 digitos"}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ width: '100%' }}>
                                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-email">Correo electrónico</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email"
                                                    type={'text'}
                                                    name={'companyEmail'}
                                                    value={`${companyData?.companyEmail}`}
                                                    onChange={(e) => inputChangeHandler(e)}
                                                    error={!!errors.companyEmail}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <EmailIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Correo electrónico"
                                                />
                                                <FormHelperText error={!!errors.companyEmail} id="outlined-contact-helper-text">{errors.companyEmail ? errors.companyEmail : "Escribe un correo electrónico que cumpla con el formato"}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', mt: 3 }}>
                                        <Button onClick={handleClose} variant="contained" size="medium" color={'secondary'} sx={{mr:2}}>Cancelar</Button>
                                        <LoadingButton
                                            loading={load}
                                            loadingPosition="start"
                                            type='submit'
                                            startIcon={<SystemUpdateAltIcon />}
                                            variant="contained"
                                        >
                                            ACTUALIZAR
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            )
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default UpdateCompanyInfo