import React from 'react'
// import { toast } from "react-toastify";
import { Box, Dialog, DialogContent, DialogTitle, Typography, Alert, FormHelperText, InputLabel, InputAdornment, OutlinedInput, FormControl } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Spinner from '../../../../custom/Spinner'
import useAuth from '../../../../../hooks/useAuth'

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PasswordIcon from '@mui/icons-material/Password';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginService from '../../../../../services/LoginService';
import BazaarService from '../../../../../services/BazzarService';

const JoinUsSteps = ({ openJoinStepsModal, setOpenJoinStepsModal, setOpenJoinUsStepsTerms }) => {
    const { token, login } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [logged, setLogged] = React.useState(false)

    const [formError, setFormError] = React.useState(false)
    const [verifyCompanyError, setVerifyCompanyError] = React.useState(false)
    const [formMessageError, setFormMessageError] = React.useState("Todos los campos son requeridos")
    const [usernameError, setUsernameError] = React.useState(false)
    const [usernameMessageError, setUsernameMessageError] = React.useState("Escribe el correo electrónico o nombre de usuario")
    const [passwordError, setPasswordError] = React.useState(false)
    const [passwordMessageError, setPasswordMessageError] = React.useState("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
    const [userdata, setUserData] = React.useState({
        username: "",
        password: ""
    })

    const closeHandler = () => {
        setOpenJoinStepsModal(false)
    }

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setUserData(userdata => {
            return {
                ...userdata,
                [name]: value
            }
        })
    }

    const fetchVerifyToken = React.useCallback(() => {
        setLoad(true)
        if (token) {
            setTimeout(() => {
                setLoad(false)
                setLogged(true)
            }, 3500)
        } else {
            setTimeout(() => {
                setLoad(false)
                setLogged(false)
            }, 2500)
        }
    }, [token])

    React.useEffect(() => {
        if (openJoinStepsModal === true) {
            void fetchVerifyToken()
        }
    }, [openJoinStepsModal, fetchVerifyToken])

    const continueJoinNextStep = async () => {
        let verifyCompany = await BazaarService.verifyCompany(token)
        // console.log(verifyCompany)
        if (verifyCompany.verifyCompany.code === "COMPANYEXIST") {
            setVerifyCompanyError(true)
            return
        }else{
            closeHandler()
            setOpenJoinUsStepsTerms(true)
        }
    }

    const submitHandlerLogin = async (e) => {
        e.preventDefault()
        setLoad(true)
        if (userdata.username === "") {
            setFormError(true)
            setFormMessageError("El username es requerido")
            setUsernameError(true)
            setUsernameMessageError("El username debe contener al menos 6 caracteres")
            setTimeout(() => {
                setFormError(false)
                setFormMessageError("Debes diligenciar todos los campos")
                setUsernameError(false)
                setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")
            }, 2000)
            setLoad(false)
        } else {
            if (userdata.password === "") {
                setFormError(true)
                setFormMessageError("La contraseña es requerida")
                setPasswordError(true)
                setPasswordMessageError("La contraseña debe contener al menos 8 caracteres")
                setTimeout(() => {
                    setFormError(false)
                    setFormMessageError("Debes diligenciar todos los campos")
                    setPasswordError(false)
                    setPasswordMessageError("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
                }, 2000)
                setLoad(false)
            } else {
                try {
                    const userlogin = await LoginService.signIn(userdata)
                    switch (userlogin.code) {
                        case "USERNAME_DONOTEXIST":
                            setFormError(true)
                            setFormMessageError("El username no existe")
                            setUsernameError(true)
                            setUsernameMessageError("El username no existe, escribe un username válido")
                            setTimeout(() => {
                                setFormError(false)
                                setFormMessageError("Debes diligenciar todos los campos")
                                setUsernameError(false)
                                setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")
                            }, 3500)
                            setLoad(false)
                            break;
                        case "EMAIL_DONOTEXIST":
                            setFormError(true)
                            setFormMessageError("El correo electrónico no existe")
                            setUsernameError(true)
                            setUsernameMessageError("El correo electrónico no existe, escribe un correo electrónico válido")
                            setTimeout(() => {
                                setFormError(false)
                                setFormMessageError("Debes diligenciar todos los campos")
                                setUsernameError(false)
                                setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")
                            }, 3500)
                            setLoad(false)
                            break;
                        case 'WRONG_PASSWORD':
                            setFormError(true)
                            setFormMessageError("La contraseña es incorrecta")
                            setPasswordError(true)
                            setPasswordMessageError("Ingresa una contraseña válida")
                            setTimeout(() => {
                                setFormError(false)
                                setFormMessageError("Debes diligenciar todos los campos")
                                setPasswordError(false)
                                setPasswordMessageError("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
                            }, 3500)
                            setLoad(false)
                            break;
                        case '200':
                            let verifyCompany = await BazaarService.verifyCompany(userlogin.token)
                            // console.log(verifyCompany)
                            login(userlogin.token, userlogin.typeUser)
                            if (verifyCompany.verifyCompany.code === "COMPANYEXIST") {
                                setVerifyCompanyError(true)
                                setLoad(false)
                                return
                            }else{
                                setLoad(false)
                                closeHandler()
                                setOpenJoinUsStepsTerms(true)
                            }

                            break;
                        default:
                            setFormError(true)
                            setFormMessageError("Hubo un error al iniciar sesión")
                            setLoad(false)
                    }
                } catch (error) {
                    setFormError(true)
                    setFormMessageError("Hubo un error al iniciar sesión")
                    setTimeout(() => {
                        setFormError(false)
                        setFormMessageError("Debes diligenciar todos los campos")
                    }, 3000)
                    setLoad(false)
                }
            }
        }
    }

    return (
        <Dialog
            open={openJoinStepsModal}
            onClose={closeHandler}
            aria-labelledby="alert-dialog-delete-modal"
            aria-describedby="alert-dialog-delete-modal"
        >
            <DialogTitle id="alert-dialog-delete-modal-title">
                {"Registro de comerciante"}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ px: 2 }}>
                    <Typography variant='h3' sx={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center', mb: 3 }}>Nos alegra que hayas tomado esta desición.</Typography>
                </Box>
                <Box sx={{ px: 2, pb: 2 }}>
                    <Typography>Paso 1</Typography>
                </Box>
                {
                    load ? (
                        <>
                            <Box>
                                <Alert severity="warning">
                                    <Typography sx={{ fontWeight: 'bold' }}>Notificación</Typography>
                                    <Typography>Estamos verificando si tienes cuenta</Typography>
                                </Alert>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', my: 6 }}>
                                <Spinner />
                            </Box>
                        </>
                    ) :
                        (
                            logged ? (
                                <>
                                    {
                                        verifyCompanyError ? (

                                            <>
                                                <Box>
                                                    <Alert severity="error">
                                                        <Typography sx={{ fontWeight: 'bold' }}>Notificación</Typography>
                                                        <Typography>Ya estas registrado como empresa</Typography>
                                                    </Alert>
                                                </Box>
                                            </>
                                        ) :
                                            (

                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 6, flexDirection: 'column' }}>
                                                    <Typography sx={{ fontSize: 16, mb: 4 }}>Ya tienes cuenta e iniciaste sesión</Typography>
                                                    <LoadingButton
                                                        loading={load}
                                                        loadingPosition="start"
                                                        onClick={() => continueJoinNextStep()}
                                                        type='button'
                                                        startIcon={<DoubleArrowIcon />}
                                                        variant="contained"
                                                    >
                                                        Continuar
                                                    </LoadingButton>
                                                </Box>
                                            )
                                    }
                                </>
                            ) :
                                (
                                    <Box
                                        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 6 }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                marginBottom: 2,
                                                color: formError ? "#FE0000" : "#111111"
                                            }}
                                        >
                                            {formMessageError}
                                        </Typography>
                                        <form style={{ width: '70%', marginTop: 6 }} onSubmit={(e) => submitHandlerLogin(e)}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <FormControl sx={{ width: '100%', marginBottom: 3 }} variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-username">Usuario o correo electrónico</InputLabel>
                                                    <OutlinedInput
                                                        sx={{
                                                            border: usernameError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                                                        }}
                                                        onChange={(e) => inputChangeHandler(e)}
                                                        id="outlined-adornment-username"
                                                        type={'text'}
                                                        name={'username'}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <SupervisedUserCircleIcon />
                                                            </InputAdornment>
                                                        }
                                                        label="Usuario o correo electrónico"
                                                    />
                                                    <FormHelperText
                                                        id="component-helper-text"
                                                        sx={{
                                                            color: usernameError ? "#FE0000" : "#111111"
                                                        }}
                                                    >
                                                        {usernameMessageError}
                                                    </FormHelperText>
                                                </FormControl>

                                                <FormControl sx={{ width: '100%', marginBottom: 1 }} variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                                    <OutlinedInput
                                                        sx={{
                                                            border: passwordError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                                                        }}
                                                        id="outlined-adornment-password"
                                                        onChange={(e) => inputChangeHandler(e)}
                                                        type={'password'}
                                                        name={'password'}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <PasswordIcon />
                                                            </InputAdornment>
                                                        }
                                                        label="Contraseña"
                                                    />
                                                    <FormHelperText
                                                        id="component-helper-text"
                                                        sx={{
                                                            color: passwordError ? "#FE0000" : "#111111"
                                                        }}
                                                    >
                                                        {passwordMessageError}
                                                    </FormHelperText>
                                                </FormControl>

                                                <LoadingButton
                                                    loading={load}
                                                    loadingPosition="start"
                                                    type='submit'
                                                    startIcon={<ExitToAppIcon />}
                                                    variant="outlined"
                                                >
                                                    Iniciar sesión
                                                </LoadingButton>

                                            </Box>
                                        </form>
                                    </Box>
                                )
                        )
                }
            </DialogContent>
        </Dialog>
    )
}

export default JoinUsSteps