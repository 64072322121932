import React from 'react';
import {Box, CssBaseline} from '@mui/material'
import MainAppBar from "./MainAppBar";
import MainDrawer from "./MainDrawer";

const MainLayout = (props) => {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = () => {
    setOpen((open) => !open);
  }

  return (
    <Box sx={{ display: 'flex', boxSizing: 'border-box' }}>
      <CssBaseline />
      <MainAppBar open={open} onToggleDrawer={toggleDrawer} onLogout={props.onLogout} />
      <MainDrawer open={open} onToggleDrawer={toggleDrawer} onLogout={props.onLogout} />
      <Box
        component="main"
        sx={{
          // backgroundColor: (theme) => theme.palette.background.default,
          backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.default.main[100] : theme.palette.background.default,
          flexGrow: 1,
          position: 'relative',
          width: '90%'
        }}
      >
        <Box
          sx={{
            minHeight: '100vh',
            paddingTop: '5rem',
            paddingX: 2,
            paddingBottom: '1.5rem',
            width: {xs:'95%', sm: '95%', md: '98%', lg: '100%', xl:'100%'}
          }}
        >
          {props.children}
        </Box>
        {/*<Box component={Footer} sx={{flexShrink: 0}}></Box>*/}
      </Box>
    </Box>
  )
}

export default MainLayout