import { createContext, useState } from "react"

const InitialToken = localStorage.getItem('tokomarket_token')
const typeUserData = localStorage.getItem('tokomarket_type_user')

const AuthProvider = ({children}) => {

    const [token, setToken] = useState(InitialToken || null)
    const [typeUser, setTypeUser] = useState(typeUserData || null)

    const login = async (token, typeUser) => {
        localStorage.setItem('tokomarket_token', token)
        localStorage.setItem('tokomarket_type_user', typeUser)
        setToken(token)
        setTypeUser(typeUser)
    }
    
    const register = async (token) => {
        localStorage.setItem('tokomarket_token', token)
        setToken(token)
    }

    const logout = () => {
        localStorage.removeItem('tokomarket_token')
        localStorage.removeItem('tokomarket_type_user')
        setToken(null)
    }

    const contextValue = {
        token,
        setToken,
        login,
        register,
        logout,
        typeUser,
        
    }
    return <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
}

export const AuthContext = createContext()
export default AuthProvider