import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const ProdAndServCategories = () => {
    const [search, setSearch] = React.useState("")
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handlerChange = (e) => {
        setSearch(e.target.value)
    }

    const handlerSubmit = (e) => {
        e.preventDefault()
        console.log(search)
    }

    return (
        <Box>
            <Box component="form" onSubmit={handlerSubmit} sx={{ p: 2 }}>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined" size={'small'}>
                    <InputLabel htmlFor="search-product-and-service-category">Búsqueda de Categorías</InputLabel>
                    <OutlinedInput
                        id="search-product-and-service-category"
                        type={'search'}
                        name={'search'}
                        value={search}
                        onChange={(e) => handlerChange(e)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Búsqueda de Categorías"
                    />
                    <FormHelperText id="search-product-and-service-category-helper-text">Encuentra productos y servicios según su clasificación</FormHelperText>
                </FormControl>
            </Box>
            <Box sx={{ width: '98%', bgcolor: 'background.paper', px:2, pb:2 }}>
                <List
                    component="nav"
                    aria-label="secondary mailbox folder"
                    sx={{
                        width: '100%',
                        // maxWidth: 300,
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 500,
                        '& ul': { padding: 0 },
                      }}
                >
                    <ListItemButton
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <ListItemText primary="Category 1" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemText primary="Category 2" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}
                    >
                        <ListItemText primary="Category 3" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}
                    >
                        <ListItemText primary="Category 4" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}
                    >
                        <ListItemText primary="Category 5" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 6}
                        onClick={(event) => handleListItemClick(event, 6)}
                    >
                        <ListItemText primary="Category 6" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 7}
                        onClick={(event) => handleListItemClick(event, 7)}
                    >
                        <ListItemText primary="Category 7" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 8}
                        onClick={(event) => handleListItemClick(event, 8)}
                    >
                        <ListItemText primary="Category 8" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 9}
                        onClick={(event) => handleListItemClick(event, 9)}
                    >
                        <ListItemText primary="Category 9" />
                    </ListItemButton>
                    <ListItemButton
                        selected={selectedIndex === 10}
                        onClick={(event) => handleListItemClick(event, 10)}
                    >
                        <ListItemText primary="Category 10" />
                    </ListItemButton>
                </List>
            </Box>
        </Box>
    )
}

export default ProdAndServCategories