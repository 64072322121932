import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import JoinUsModal from './Modals/JoinUsModal'
import JoinUsSteps from './Modals/JoinUsSteps/JoinUsSteps'
import JoinUsStepsTerms from './Modals/JoinUsSteps/JoinUsStepsTerms'
import JoinUsStepsWelcome from './Modals/JoinUsSteps/JoinUsStepsWelcome'

const JoinUs = () => {
    const [openJoinUsModal, setOpenJoinModal] = React.useState(false)
    const [openJoinStepsModal, setOpenJoinStepsModal] = React.useState(false)
    const [openJoinUsStepsTerms, setOpenJoinUsStepsTerms] = React.useState(false)
    const [openJoinUsStepsWelcome, setOpenJoinUsStepsWelcome] = React.useState(false)

    return (
        <>
            <Box sx={{ 
                display: 'flex',
                justifyContent: {xs:'none', sm:'space-between', md:'space-between'},
                flexDirection:{xs:'column', sm:'column', md:'row'} 
                }}>
                <Paper sx={{ 
                        width: {sx:'100%', sm:'100%', md:'49%', lg:'49%', xl:'49%'},
                        p: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: {xs:2, sm:2, md:0, lg:0, xl:0},
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Typography variant='h4'
                            sx={{
                                fontSize: 22,
                                p: 2,
                                textAlign: 'center'
                            }}
                        >
                            Únetenos, se parte de nuestros comerciantes
                        </Typography>
                        <Typography variant='p' sx={{
                            fontSize: 16,
                            p: 2,
                            textAlign: 'center'
                        }}>
                            Puedes ofrecer tus productos o servicios en nuestra plataforma.
                        </Typography>
                        <Typography variant='p' sx={{
                            fontSize: 14,
                            p: 2,
                            textAlign: 'center'
                        }}>
                            Revisa nuestros términos y condiciones.
                        </Typography>
                        <Button onClick={() => setOpenJoinModal(true)} variant="contained" size="medium" sx={{ width: '30%', margin: 'auto' }}>
                            Únete aquí
                        </Button>
                    </Box>
                </Paper>
                <Paper sx={{ 
                        width: {sx:'100%', sm:'100%', md:'49%', lg:'49%', xl:'49%'},
                        p: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: {xs:2, sm:2, md:0, lg:0, xl:0},
                    }}>
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <img style={{ width: '100%' }} alt='Unete aqui' src='/asset/images/tacones-2-1.jpg' />
                    </Box>
                </Paper>
            </Box>
            <JoinUsModal setOpenJoinModal={setOpenJoinModal} openJoinUsModal={openJoinUsModal} setJoinStepsModal={setOpenJoinStepsModal} />
            <JoinUsSteps openJoinStepsModal={openJoinStepsModal} setOpenJoinStepsModal={setOpenJoinStepsModal} setOpenJoinUsStepsTerms={setOpenJoinUsStepsTerms} />
            <JoinUsStepsTerms openJoinUsStepsTerms={openJoinUsStepsTerms} setOpenJoinUsStepsTerms={setOpenJoinUsStepsTerms} setOpenJoinUsStepsWelcome={setOpenJoinUsStepsWelcome} />
            <JoinUsStepsWelcome openJoinUsStepsWelcome={openJoinUsStepsWelcome} setOpenJoinUsStepsWelcome={setOpenJoinUsStepsWelcome} />
        </>
    )
}

export default JoinUs