import React from 'react'
import { Box, Paper, Typography, Button, List, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : '#308fe8',
    },
  }));

const Plan = () => {
    return (
        <>
            <Paper
                sx={{
                    mt: 3,
                    p: 2,
                    border: 'solid 2px',
                    borderColor: (theme) => theme.palette.primary.main,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: "lightblue",
                                color: 'white',
                                borderRadius: '2rem',
                                textAlign: 'center',
                                width: '60%',
                                height: '1.5rem'
                            }}
                        >
                            Free
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItema: 'flex-start'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >

                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                    fontSize: '2rem',
                                    mt: 8,
                                    mr: 1
                                }}
                            >
                                $
                            </Typography>
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                    fontSize: '6rem'
                                }}
                            >
                                0
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    mt: 10,
                                    ml: 1
                                }}
                            >
                                / month
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <List>
                        <ListItem><Typography sx={{ color: (theme) => theme.palette.default.main[400] }}>Item Commerce totales: 25</Typography></ListItem>
                        <ListItem><Typography sx={{ color: (theme) => theme.palette.default.main[400] }}>Images by Items: 3</Typography></ListItem>
                        <ListItem><Typography sx={{ color: (theme) => theme.palette.default.main[400] }}>Create User: 2</Typography></ListItem>
                    </List>
                </Box>
                <Box>
                    <Box
                        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Typography>
                            Días
                        </Typography>
                        <Typography>
                            15 de 30
                        </Typography>
                    </Box>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography>
                        15 días faltantes
                    </Typography>
                    <Box
                        sx={{ mt: 3, width: '100%' }}
                    >
                        <Button variant="contained" size="medium" color="primary" sx={{ width: '100%' }}>
                            Subir Plan
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}

export default Plan