import React from 'react'
import { Avatar, Typography, Box } from '@mui/material';

const InterestItem = () => {
    return (
        <>
            <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}
            >
                <Avatar
                    sx={{
                        width: 70,
                        height: 70,
                        mb: 2
                    }}
                    alt="Data for test"
                    src="/asset/images/avatar1.png"
                />
                <Typography>Hello1</Typography>
            </Box>
        </>
    )
}

export default InterestItem