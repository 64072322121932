import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';
import CompanyActivityItem from './CompanyActivityItem';

const CompanyActivity = () => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [error, setError] = React.useState(null)

    if (load) {
        return (
            <>
                <Paper sx={{ m: 1, height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner />
                    </Box>
                </Paper>
            </>
        )
    } else {
        return (
            <Paper sx={{ m: 1 }}>
                <Box>
                    <Typography sx={{ fontSize: 18, p: 2, color: '#745856', }}>Actividad de comercio</Typography>
                    <Box sx={{border:1, borderColor:'divider'}}></Box>
                    {
                        error ? (
                            <Typography sx={{ fontSize: '1rem', ml: 1, mb: 3, pt:2 }}>{error}</Typography>
                        ) :
                            (
                                <Box sx={{py:2}}>
                                    <CompanyActivityItem />
                                    <CompanyActivityItem />
                                    <CompanyActivityItem />
                                    <CompanyActivityItem />
                                    <CompanyActivityItem />
                                    <CompanyActivityItem />
                                </Box>
                            )
                    }
                </Box>
            </Paper>
        )
    }
}

export default CompanyActivity