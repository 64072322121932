import React from 'react'
import { toast } from "react-toastify";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText, Typography, Alert } from '@mui/material'
import AccountService from '../../../../services/AccountService';
import useAuth from '../../../../hooks/useAuth';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteAccount = ({ openDeleteAccountModal, setOpenDeleteAccountModal, infoAccount }) => {
    const { token, logout } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [textDelete, setTextDelete] = React.useState('')
    const [isValidText, setIsValidText] = React.useState(false)
    const [account, setAccount] = React.useState(infoAccount)

    //Functions
    const handleClose = () => {
        setOpenDeleteAccountModal(false);
        setTextDelete("")
        setIsValidText(false)
    }

    const inputChangeHandler = e => {
        let textObtain = 'eliminar cuenta'
        setTextDelete(e.target.value)
        if (textObtain === e.target.value) {
            setIsValidText(true)
        } else {
            setIsValidText(false)
        }
    }

    const handleSumbit = async (e) => {
        e.preventDefault()
        setLoad(true)
        try {
            let deletedAccount = await AccountService.deleteAccount(token, account)
            switch (deletedAccount.code) {
                case '500':
                    console.log(deletedAccount.msg)
                    toast.error(deletedAccount.msg, 2000)
                    setLoad(false)
                    break;
                case '200':
                    toast.success("Se ha eliminado la cuenta correctamente", 2000)
                    setOpenDeleteAccountModal(false)
                    setLoad(false)
                    setAccount('')
                    logout()
                    break;
                default:
                    toast.error("code undefined, el código a validar no existe", 2000)
                    setLoad(false)
            }
        } catch (error) {
            console.log(error)
            toast.error("Hubo un error al eliminar la cuenta", 2000)
            setLoad(false)
        }
    }

    return (
        <>
            <Dialog
                maxWidth={'xs'}
                open={openDeleteAccountModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-delete-modal"
                aria-describedby="alert-dialog-delete-modal"
            >
                <DialogTitle id="alert-dialog-delete-modal-title">
                    {"Eliminar cuenta"}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ p: 2 }}>
                        <Typography>Nos entristece que elimines tu cuenta</Typography>
                    </Box>
                    <Box>
                        <Alert severity="error">
                            <Typography sx={{ fontWeight: 'bold' }}>Cuidado</Typography>
                            <Typography>Esta acción eliminara su cuenta y toda la información referente a ella. ¿Está seguro que desea realizar esta acción?</Typography>
                        </Alert>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Typography>Para eliminar la cuenta escribe la información a continuación</Typography>
                    </Box>
                    <Box>
                        <Box component="form" onSubmit={handleSumbit} sx={{ p: 2 }}>
                            <Box sx={{ width: '100%', mr: 3 }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-names">Escribe "eliminar cuenta"</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-delete-account"
                                        type={'text'}
                                        name={'delete'}
                                        value={`${textDelete}`}
                                        onChange={(e) => inputChangeHandler(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Escribe eliminar cuenta"
                                    />
                                    <FormHelperText id="outlined-weight-helper-text">Escribe en el campo de texto: eliminar cuenta</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', mt: 3 }}>
                                <Button onClick={handleClose} variant="contained" size="medium" color={'secondary'} sx={{mr:2}}>Cancelar</Button>
                                {
                                    isValidText ? (
                                        <LoadingButton
                                            loading={load}
                                            loadingPosition="start"
                                            type='submit'
                                            startIcon={<DeleteIcon />}
                                            variant="contained"
                                            color={'error'}
                                        >
                                            ELIMINAR CUENTA
                                        </LoadingButton>
                                    )
                                        :
                                        (
                                            <LoadingButton
                                                loading={load}
                                                loadingPosition="start"
                                                type='button'
                                                startIcon={<DeleteIcon />}
                                                variant="contained"
                                                color={'default'}
                                            >
                                                ELIMINAR CUENTA
                                            </LoadingButton>
                                        )
                                }
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeleteAccount