import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import UserActivityItem from './UserActivityItem'
import AccountService from '../../../services/AccountService'
import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';

const UserActivity = () => {
  const { token } = useAuth()
  const [load, setLoad] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [userActivities, setUserActivities] = React.useState([])

  //Functions
  const getUserActivity = React.useCallback(async (token) => {
    setLoad(true)

    try {
      let getUserActivityData = await AccountService.getUserActivity(token)
      // console.log(getUserActivityData)
      if(getUserActivityData.userActivity){
        setUserActivities(getUserActivityData.userActivity)
        setLoad(false)
      }else{
        setError(getUserActivityData.msg)
        setLoad(false)
      }
    } catch (error) {
      setError(error.message)
      setLoad(false)
    }

  }, [])

  //useEffect
  React.useEffect(() => {
    void getUserActivity(token)
  }, [getUserActivity, token])

  if (load) {
    return (
      <>
        <Paper sx={{ height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner />
          </Box>
        </Paper>
      </>
    )
  } else {
    return (
      <Paper sx={{ pt: 2, mt: 3, pb: 2 }} >
        <Typography sx={{ fontSize: '1.5rem', ml: 1, mb: 3 }}>User Activity</Typography>
        {
          error ? (
            <Typography sx={{ fontSize: '1rem', ml: 1, mb: 3 }}>{error}</Typography>
          ) :
            (
              <>
                {userActivities.map((activity, index) => {
                  return <UserActivityItem key={index} activity={activity}/>
                })}
              </>
            )
        }
      </Paper>
    )
  }
}

export default UserActivity