import React from 'react'
import useAuth from '../../../hooks/useAuth'
import MainLayout from '../../layouts/mainLayout/MainLayout'
import GlobalNotificationProvider from '../../../hooks/GlobalNotificationProvider'
import { Box, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import ComputerIcon from '@mui/icons-material/Computer';
import StoreIcon from '@mui/icons-material/Store';
import Diversity3Icon from '@mui/icons-material/Diversity3';

//Custom components
import Header from './Header'
import AboutCompany from './AboutCompany'
import CompanyActivity from './CompanyActivity'
import LastVisit from './LastVisit'
import Commerce from './Commerce'
import SocialCompanyInfo from './SocialCompanyInfo'
import CompanyComments from './CompanyComments'
import CompanyOwner from './CompanyOwner'

const initialData = {
    companyName: "",
    companyType: "",
    companyStatus: "private",
    companyCountry:"",
    companyContact: "3000000000",
    companyEmail:"correo@correo.com",
    companyProductType:"",
    companyScore:"",
    companyVerifyEmail:false,
    companyVerifyContact:false,
    companyTotalView:0,
    companyTotalSale:0,
    companyViewPoints:0,
    plan:{},
    socialLikes:0,
    socialDislikes:0,
    socialPublished:0,
    companyTotalComments:0,
    companyUserScore:0,
    companyLogo:"",
    companyBanner:""
}

const Company = () => {
    const { logout } = useAuth()
    const [value, setValue] = React.useState('one');
    const [openUpdateCompanyInfo, setOpenUpdateCompanyInfo] = React.useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainLayout onLogout={logout}>
            <GlobalNotificationProvider>
                <Box
                    sx={{
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.default.main[100] : theme.palette.background.default,
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'column' }
                    }}
                >
                    <Box sx={{ width: '100%', position: 'relative' }}>
                        <Header initialData={initialData} openUpdateCompanyInfo={openUpdateCompanyInfo}/>
                    </Box>
                    <Box sx={{ width: '100%', position: 'relative', mt: 4 }}>
                        <TabContext
                            value={value}
                            textColor="primary"
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="Options Tabs Company"
                        >
                            <TabList onChange={handleChange}>
                                <Tab value="one" label="Perfil" icon={<ComputerIcon />} iconPosition="start" />
                                <Tab value="two" label="Comercio" icon={<StoreIcon />} iconPosition="start" />
                                <Tab value="three" label="Social" icon={<Diversity3Icon />} iconPosition="start" />
                            </TabList>

                            <TabPanel value="one">
                                <Box
                                    sx={{
                                        display:'flex',
                                        flexDirection: {xs:'column', sm: 'column', md:'column', lg:'row', xl:'row'},
                                        boxSizing:'border-box'
                                    }}
                                >
                                    <Box
                                        width={'25%'}
                                    >
                                        <AboutCompany initialData={initialData} openUpdateCompanyInfo={openUpdateCompanyInfo} setOpenUpdateCompanyInfo={setOpenUpdateCompanyInfo}/>
                                        <CompanyOwner initialData={initialData}/>
                                    </Box>
                                    <Box
                                        width={'75%'}
                                    >
                                        <CompanyActivity/>
                                        <Box sx={{width:'100%'}}>
                                            <Box sx={{width: '50%'}}>
                                                <LastVisit/>
                                            </Box>
                                            <Box sx={{width: '50%'}}>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel value="two">
                                <Box>
                                    <Commerce initialData={initialData}/>
                                </Box>
                            </TabPanel>
                            <TabPanel value="three">
                                <Box
                                    sx={{
                                        display:'flex',
                                        flexDirection: {xs:'column', sm: 'column', md:'column', lg:'row', xl:'row'},
                                        boxSizing:'border-box'
                                    }}
                                >
                                    <Box
                                        width={'25%'}
                                    >
                                        <SocialCompanyInfo initialData={initialData}/>
                                    </Box>
                                    <Box width={'75%'}>
                                        <CompanyComments/>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>
            </GlobalNotificationProvider>
        </MainLayout>
    )
}

export default Company