import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const ProductAndServiceItem = () => {
  return (
    <Card sx={{ width:300, maxWidth: 280 }}>
      <CardActionArea>
        <CardMedia
        sx={{pt:3}}
          component="img"
          height="200"
          image="/asset/images/lavadora.png"
          alt="green iguana"
        />
        <CardContent>
          <Typography 
            gutterBottom
            variant="h5"
            component="div"
            sx={{fontSize:16}}
        >
            Product Name
          </Typography>
          <Typography variant="p" color="text.secondary" sx={{fontSize:14}}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni, reprehenderit!
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ProductAndServiceItem