import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Card, CardContent, Avatar, FormHelperText, InputLabel, InputAdornment, OutlinedInput, FormControl, FormControlLabel, Checkbox } from '@mui/material'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PasswordIcon from '@mui/icons-material/Password';
import LoadingButton from '@mui/lab/LoadingButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EmailIcon from '@mui/icons-material/Email';
import RegisterService from '../../../services/RegisterService';
import useAuth from '../../../hooks/useAuth'

const Register = () => {
    const { login } = useAuth()
    //States
    const [load, setLoad] = React.useState(false)
    const [formError, setFormError] = React.useState(false)
    const [formMessageError, setFomrMessageError] = React.useState("Debes diligenciar todos los campos")
    const [usernameError, setUsernameError] = React.useState(false)
    const [usernameMessageError, setUsernameMessageError] = React.useState("Este dato es requerido* (Escribe un nombre de usuario sin espacios)")
    const [emailError, setEmailError] = React.useState(false)
    const [emailMessageError, setEmailMessageError] = React.useState("Este dato es requerido* (Escribe un correo electrónico válido)")
    const [passwordError, setPasswordError] = React.useState(false)
    const [passwordMessageError, setPasswordMessageError] = React.useState("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
    const [userdata, setUserData] = React.useState({
        username: "",
        email: "",
        password: "",
        type: "User",
        accept: false
    })

    const navigate = useNavigate()

    //functions

    const inputChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setUserData(userdata => {
            return {
                ...userdata,
                [name]: value
            }
        })
    }

    const validationInputs = React.useCallback((userdata) => {
        setUsernameError(false)
        setUsernameMessageError("Este dato es requerido* (Escribe un nombre de usuario sin espacios)")
        setEmailError(false)
        setEmailMessageError("Este dato es requerido* (Escribe un correo electrónico válido)")
        setPasswordError(false)
        setPasswordMessageError("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")

        if (userdata.username.length > 3 && userdata.username.length < 6) {
            setUsernameError(true)
            setUsernameMessageError("El username debe contener al menos 6 caracteres")
        } else {
            let validEmail = /^[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9*+/={|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g
            let isValidEmail = userdata.email.match(validEmail)
            if (userdata.email.length > 10 && !isValidEmail) {
                setEmailError(true)
                setEmailMessageError("Debes ingresar un email válido")
            } else {
                if (userdata.password.length > 4 && userdata.password.length < 6) {
                    setPasswordError(true)
                    setPasswordMessageError("La contraseña debe contener al menos 8 caracteres")
                }
            }
        }

    }, [])

    const handleSubmit = async () => {
        setLoad(true)
        if (userdata.accept === false) {
            setFormError(true)
            setFomrMessageError("Acepta los términos y condiciones")
            setTimeout(() => {
                setFormError(false)
                setFomrMessageError("Debes diligenciar todos los campos")
            }, 1000)
            setLoad(false)
        } else {
            if (userdata.username === "") {
                setFormError(true)
                setFomrMessageError("El username es requerido")
                setUsernameError(true)
                setUsernameMessageError("El username debe contener al menos 6 caracteres")
                setTimeout(() => {
                    setFormError(false)
                    setFomrMessageError("Debes diligenciar todos los campos")
                    setUsernameError(false)
                    setUsernameMessageError("Este dato es requerido* (Escribe un nombre de usuario sin espacios)")
                }, 2000)
                setLoad(false)
            } else {
                if (userdata.email === "") {
                    setFormError(true)
                    setFomrMessageError("El email es requerido")
                    setEmailError(true)
                    setEmailMessageError("Debes ingresar un email válido")
                    setTimeout(() => {
                        setFormError(false)
                        setFomrMessageError("Debes diligenciar todos los campos")
                        setEmailError(false)
                        setEmailMessageError("Este dato es requerido* (Escribe un correo electrónico válido)")
                    }, 2000)
                    setLoad(false)
                } else {
                    if (userdata.password === "") {
                        setFormError(true)
                        setFomrMessageError("La contraseña es requerida")
                        setPasswordError(true)
                        setPasswordMessageError("La contraseña debe contener al menos 8 caracteres")
                        setTimeout(() => {
                            setFormError(false)
                            setFomrMessageError("Debes diligenciar todos los campos")
                            setPasswordError(false)
                            setPasswordMessageError("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
                        }, 2000)
                        setLoad(false)
                    } else {
                        try {
                            //console.log(userdata)
                            let signUp = await RegisterService.signUp(userdata)
                            //console.log(signUp)
                            login(signUp.token, signUp.typeUser)
                            setLoad(false)
                        } catch (error) {
                            setFormError(true)
                            setFomrMessageError("Hubo un error al registrarse")
                            setTimeout(() => {
                                setFormError(false)
                                setFomrMessageError("Debes diligenciar todos los campos")
                            }, 2000)
                            setLoad(false)
                        }
                    }
                }
            }
        }
    }

    //UseEffect
    React.useEffect(() => {
        validationInputs(userdata)
    }, [validationInputs, userdata])

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0,
                overflowX: 'hidden',
                overflowY: 'hidden',
                // backgroundColor: 'red',
                position: 'absolute',
                display: 'flex'
            }}
        >
            <Box
                sx={{
                    width: '33%',
                    height: '100hv',
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                    justifyContent: 'center',
                    backgroundColor: '#304fba',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    padding: 3
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 44,
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            lineHeight: 1,
                            marginBottom: 4,
                            color: '#dddddd'
                        }}
                    >
                        Hola!, Amig@ o Emprendimiento
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 24,
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            marginBottom: 2,
                            color: '#dddddd'
                        }}
                    >
                        Bienvenid@ a Tokomarket
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 20,
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            color: '#dddddd'
                        }}
                    >
                        Diligencia los datos para ingresar, registrarte y acceder a nuestra plataforma.
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 20,
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            marginY: 3,
                            color: '#dddddd'
                        }}
                    >
                        ó
                    </Typography>
                    <Typography
                        onClick={() => navigate('/')}
                        sx={{
                            textAlign: 'center',
                            fontSize: 20,
                            fontWeight: 'bold',
                            fontFamily: 'sans-serif',
                            color: '#dddddd',
                            cursor: 'pointer'
                        }}
                    >
                        Haz click aquí para iniciar sesión.
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: { xs: '100%', sm: '100%', md: '67%', lg: '67%' },
                    height: '100hv',
                    backgroundColor: '#DEDEDE',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Card
                    sx={{
                        width: { xs: '100%', sm: '80%', md: '60%', lg: '60%', xl: '50%' },
                        boxShadow: 3
                    }}
                >
                    <CardContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar
                                src='/asset/logo_tokomarket_lightblue.png'
                                alt='Tokomarket logo lightblue'
                                sx={{
                                    width: 100,
                                    height: 100,
                                    marginTop: 3
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: 24,
                                    marginBottom: 5
                                }}
                            >Tokomarket</Typography>

                            <Typography
                                sx={{
                                    fontSize: 16,
                                    marginBottom: 2,
                                    color: formError ? "#FE0000" : "#111111"
                                }}
                            >
                                {formMessageError}
                            </Typography>

                        </Box>
                        <form>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <FormControl sx={{ width: '100%', marginBottom: 3 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-username">Usuario</InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            border: usernameError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                                        }}
                                        onChange={(e) => inputChangeHandler(e)}
                                        id="outlined-adornment-username"
                                        type={'text'}
                                        name={'username'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <SupervisedUserCircleIcon />
                                            </InputAdornment>
                                        }
                                        label="Usuario"
                                    />
                                    <FormHelperText
                                        id="component-helper-text"
                                        sx={{
                                            color: usernameError ? "#FE0000" : "#111111"
                                        }}
                                    >
                                        {usernameMessageError}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl sx={{ width: '100%', marginBottom: 3 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-email">Correo electrónico</InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            border: emailError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                                        }}
                                        onChange={(e) => inputChangeHandler(e)}
                                        id="outlined-adornment-email"
                                        type={'text'}
                                        name={'email'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <EmailIcon />
                                            </InputAdornment>
                                        }
                                        label="Correo electrónico"
                                    />
                                    <FormHelperText
                                        id="component-helper-text"
                                        sx={{
                                            color: emailError ? "#FE0000" : "#111111"
                                        }}
                                    >
                                        {emailMessageError}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl sx={{ width: '100%', marginBottom: 1 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            border: passwordError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                                        }}
                                        id="outlined-adornment-password"
                                        onChange={(e) => inputChangeHandler(e)}
                                        type={'password'}
                                        name={'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <PasswordIcon />
                                            </InputAdornment>
                                        }
                                        label="Contraseña"
                                    />
                                    <FormHelperText
                                        id="component-helper-text"
                                        sx={{
                                            color: passwordError ? "#FE0000" : "#111111"
                                        }}
                                    >
                                        {passwordMessageError}
                                    </FormHelperText>
                                </FormControl>

                                <FormControlLabel
                                    sx={{
                                        marginBottom: 3
                                    }}
                                    control={<Checkbox name={'accept'} onChange={(e) => inputChangeHandler(e)} />}
                                    label="Acepta términos y condiciones"
                                />

                                <LoadingButton
                                    loading={load}
                                    onClick={() => handleSubmit()}
                                    loadingPosition="start"
                                    startIcon={<ExitToAppIcon />}
                                    variant="outlined"
                                >
                                    Registrarse
                                </LoadingButton>

                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </Box>

        </Box>
    )
}

export default Register