import React from 'react'
import { Box, Typography } from '@mui/material'
import { convertDate, obtainClock } from '../../../helpers/utils'

const UserActivityItem = ({ activity }) => {
    return (
        <Box
            sx={{
                width: { xs: '100%', sm: '97%', md: '97%', lg: '97%', xl: '97%' }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                    paddingBottom: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: { xs: '100%', sm: '100%', md: '80%', lg: '80%', xl: '80%' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '20%',
                            
                        }}
                    >
                        <Box
                            component={'div'}
                            sx={{
                                width: '0.8rem',
                                height: '0.8rem',
                                backgroundColor: (theme) => theme.palette.primary.main,
                                borderRadius: '50%',
                                mb: 1
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: '0.2rem',
                                height: '3.2rem',
                                backgroundColor: (theme) => theme.palette.default.main[200]
                            }}
                        >
                        </Box>
                    </Box>

                    <Box
                        sx={{
                           width: '80%',
                           
                        }}
                    >
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.default.main[600],
                                fontWeight: 'bold'
                            }}
                        >{activity.typeActivity}</Typography>
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.default.main[400]
                            }}
                        >{activity.description} a las {obtainClock(activity.createdAt)}</Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: { xs: '100%', sm: '60%', md: '30%', lg: '20%', xl: '20%' },
                    }}
                >
                    <Box
                        sx={{
                            // width: { xs: '100%', sm: '100%', md: '100%', lg: '20%', xl: '20%' },
                            width: '100%',
                            marginLeft: { xs: 6, sm: 10, md: 10, lg: 0, xl: 0 },
                        }}
                    >
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.default.main[600]
                            }}
                        ><b>{convertDate(activity.createdAt)}</b></Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default UserActivityItem