import React from 'react'
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
//Imports views
import Login from '../views/login/Login'

import Register from '../views/register/Register'
import PublicRoutes from '../../hooks/PublicRoutes'
import RequireAuth from '../../hooks/RequireAuth'
import Account from '../views/account/Account'
import Bazaar from '../views/bazaar/Bazaar';
import RequireAuthCompany from '../../hooks/RequireAuthCompany';
import Company from '../views/company/Company';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Bazaar/>,
  },
  {
    path: "/login",
    element: <PublicRoutes><Login/></PublicRoutes>,
  },
  {
    path: "/register",
    element: <PublicRoutes><Register/></PublicRoutes>,

  },
  {
    path: "/account",
    element: <RequireAuth><Account/></RequireAuth>,
  },
  {
    path: "/company",
    element: <RequireAuthCompany><Company/></RequireAuthCompany>,
  }
]);

const RootRouter = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default RootRouter