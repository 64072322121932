import { useLocation, Navigate } from "react-router-dom"
import useAuth from "./useAuth"

const RequireAuth = ({children}) => {
    const {token} = useAuth()
    let location = useLocation()

    if(!token){
        return children
    }else{
        return <Navigate to="/account" state={{ from: location }} replace />;
        // if(user.role === 'admin'){
        //     return <Navigate to="/admin/dashboard" state={{ from: location }} replace />;
        // }else{
        //     return <Navigate to="/user/dashboard" state={{ from: location }} replace />;
        // }
    }
}

export default RequireAuth