import React from 'react'
import { Paper, Typography, Box, Button, Alert } from '@mui/material'
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : '#308fe8',
    },
}));

const CurrentPlan = () => {
    return (
        <Paper sx={{ pt: 2 }} >
            <Typography sx={{ fontSize: '1.5rem', ml: 2, mb: 3 }}>Current Plan</Typography>
            <Box sx={{ m: 2 }}>
                <Box sx={{display:'flex'}}>
                    <Box sx={{ width: '50%' }}>
                        <Typography>Your Current Plan is <b>Basic</b></Typography>
                        <Typography>A simple start for everyone</Typography>

                        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>Active until Dec 09, 2021</Typography>
                        <Typography>We will send you a notification upon Subscription expiration.</Typography>

                        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>$0 Per Month</Typography>
                        <Typography>Standard plan for small to medium businesses.</Typography>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <Alert severity="warning">
                            <Typography sx={{ fontWeight: 'bold' }}>We need your attention!</Typography>
                            <Typography>Your plan requires updates</Typography>
                        </Alert>
                        <Box sx={{my:2}}>
                            <Box
                                sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                            >
                                <Typography>
                                    Días
                                </Typography>
                                <Typography>
                                    15 de 30
                                </Typography>
                            </Box>
                            <BorderLinearProgress variant="determinate" value={50} />
                            <Typography>
                                15 días faltantes
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ m: 2, pb:3 }}>
                <Button variant="contained" size="large" sx={{ mr: 2 }}>UPGRADE PLAN</Button>
                <Button variant="outlined" size="large" color="error">CANCEL SUSCRIPTION</Button>
            </Box>
        </Paper>
    )
}

export default CurrentPlan