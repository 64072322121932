//Production variable
const production = true

let localUrl = 'http://localhost:4000'
let remoteURL = 'https://qa.backend.tokomarket.net'

const ConfigServer = {
    urlServer: production ? remoteURL : localUrl
}

export default ConfigServer