import React from 'react';
import { Box } from '@mui/material'
import AppBar from './ResponsiveAppBar'

const PublicLayout = (props) => {
    return (
        <>
            <AppBar />
            <Box
                component="main"
                sx={{ 
                    display: 'flex',
                    boxSizing: 'border-box',
                    // backgroundColor: 'red',
                    maxWidth:'100vw',
                    overflowX:'hidden',
                    width: '100%',
                    backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.default.main[100] : theme.palette.background.default,
                }}>
                <Box
                    sx={{
                        flexGrow: 1,
                        position: 'relative',
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            minHeight: '100vh',
                            paddingTop: '1rem',
                            paddingX: 2,
                            paddingBottom: '1.5rem',
                            width: '100%'
                        }}
                    >
                        {props.children}
                    </Box>
                    {/*<Box component={Footer} sx={{flexShrink: 0}}></Box>*/}
                </Box>
            </Box>
        </>
    )
}

export default PublicLayout