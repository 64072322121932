import React from 'react'

import ProductAndServiceItem from './ProductAndServiceItem';
import { Box, Grid } from '@mui/material';

const PoductsAndServices = () => {
    return (
        <>
        <Box
            sx={{
                p:1,
                ml:2
            }}
        >
           <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{mb:2, display:'flex', justifyContent:'center'}}>
                    <ProductAndServiceItem/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{mb:2, display:'flex', justifyContent:'center'}}>
                    <ProductAndServiceItem/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{mb:2, display:'flex', justifyContent:'center'}}>
                    <ProductAndServiceItem/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{mb:2, display:'flex', justifyContent:'center'}}>
                    <ProductAndServiceItem/>
                </Grid>
           </Grid>
        </Box>
        </>
    )
}

export default PoductsAndServices