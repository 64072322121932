import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {Typography, Paper} from '@mui/material';


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import SeasonProductItem from './SeasonProductItem';

const SeasonProducts = () => {

    return (
        <Paper>
            <Typography
                sx={{
                    fontSize: '1.4rem',
                    p: 2
                }}
                variant='h3'
            >Productos y Servicios por temporada</Typography>
            <Typography
                sx={{
                    fontSize: '1rem',
                    px: 2,
                    pb:2
                }}
                variant='h4'
            >Sólo por tiempo limitado</Typography>

            <Swiper
                style={{paddingLeft:0, paddingBottom:25}}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{
                    680: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1333: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1500: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                }}
                coverflowEffect={{
                    rotate: 40,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
            >
                <SwiperSlide>
                    <SeasonProductItem/>
                </SwiperSlide>
                <SwiperSlide>
                    <SeasonProductItem/>
                </SwiperSlide>
                <SwiperSlide>
                    <SeasonProductItem/>
                </SwiperSlide>
                <SwiperSlide>
                    <SeasonProductItem/>
                </SwiperSlide>
                <SwiperSlide>
                    <SeasonProductItem/>
                </SwiperSlide>
            </Swiper>
        </Paper>
    )
}

export default SeasonProducts