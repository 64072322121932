import { Link, Typography } from '@mui/material';
import React from 'react'

const Footer = (props) => {
    return (
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          mt={3}
          {...props}
        >
          {"Copyright © "}
          <Link color="inherit" href="https://tokomarket.net">
            Tokomarket
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      );
}

export default Footer