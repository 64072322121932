import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Card, CardContent, Avatar, FormHelperText, InputLabel, InputAdornment, OutlinedInput, FormControl } from '@mui/material'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PasswordIcon from '@mui/icons-material/Password';
import LoadingButton from '@mui/lab/LoadingButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginService from '../../../services/LoginService';
import useAuth from '../../../hooks/useAuth'

const Login = () => {
  const { login } = useAuth()
  //Inputs Validation
  // let validEmail = /^[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9*+/={|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g
  //states
  const [load, setLoad] = React.useState(false)
  const [formError, setFormError] = React.useState(false)
  const [formMessageError, setFormMessageError] = React.useState("Todos los campos son requeridos")
  const [usernameError, setUsernameError] = React.useState(false)
  const [usernameMessageError, setUsernameMessageError] = React.useState("Escribe el correo electrónico o nombre de usuario")
  const [passwordError, setPasswordError] = React.useState(false)
  const [passwordMessageError, setPasswordMessageError] = React.useState("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
  const [userdata, setUserData] = React.useState({
    username: "",
    password: ""
  })

  const navigate = useNavigate()

  //functions

  const inputChangeHandler = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setUserData(userdata => {
      return {
        ...userdata,
        [name]: value
      }
    })
  }

  const ValidationInputUsername = React.useCallback((userdata) => {
    setUsernameError(false)
    setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")

    let validEmail = /^[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9*+/={|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g
    let isValidEmail = userdata.username.match(validEmail)
    if (userdata.username.length > 6) {
      if (isValidEmail) {
        console.log('email')
      } else {
        console.log('username')
      }
    } else {
      if (userdata.username.length > 2) {
        setUsernameError(true)
        setUsernameMessageError('Debes especificar un nombre de usuario válido')
      }
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoad(true)
    if (userdata.username === "") {
      setFormError(true)
      setFormMessageError("El username es requerido")
      setUsernameError(true)
      setUsernameMessageError("El username debe contener al menos 6 caracteres")
      setTimeout(() => {
        setFormError(false)
        setFormMessageError("Debes diligenciar todos los campos")
        setUsernameError(false)
        setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")
      }, 2000)
      setLoad(false)
    } else {
      if (userdata.password === "") {
        setFormError(true)
        setFormMessageError("La contraseña es requerida")
        setPasswordError(true)
        setPasswordMessageError("La contraseña debe contener al menos 8 caracteres")
        setTimeout(() => {
          setFormError(false)
          setFormMessageError("Debes diligenciar todos los campos")
          setPasswordError(false)
          setPasswordMessageError("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
        }, 2000)
        setLoad(false)
      } else {
        try {
          const userlogin = await LoginService.signIn(userdata)
          switch (userlogin.code) {
            case "USERNAME_DONOTEXIST":
              setFormError(true)
              setFormMessageError("El username no existe")
              setUsernameError(true)
              setUsernameMessageError("El username no existe, escribe un username válido")
              setTimeout(() => {
                setFormError(false)
                setFormMessageError("Debes diligenciar todos los campos")
                setUsernameError(false)
                setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")
              }, 3500)
              setLoad(false)
              break;
            case "EMAIL_DONOTEXIST":
              setFormError(true)
              setFormMessageError("El correo electrónico no existe")
              setUsernameError(true)
              setUsernameMessageError("El correo electrónico no existe, escribe un correo electrónico válido")
              setTimeout(() => {
                setFormError(false)
                setFormMessageError("Debes diligenciar todos los campos")
                setUsernameError(false)
                setUsernameMessageError("Escribe el correo electrónico o nombre de usuario")
              }, 3500)
              setLoad(false)
              break;
            case 'WRONG_PASSWORD':
              setFormError(true)
              setFormMessageError("La contraseña es incorrecta")
              setPasswordError(true)
              setPasswordMessageError("Ingresa una contraseña válida")
              setTimeout(() => {
                setFormError(false)
                setFormMessageError("Debes diligenciar todos los campos")
                setPasswordError(false)
                setPasswordMessageError("Este dato es requerido* (Escribe una contraseña de más de 8 caracteres)")
              }, 3500)
              setLoad(false)
              break;
            case '200':
              console.log(userlogin)
              login(userlogin.token, userlogin.typeUser)
              setLoad(false)
              break;
            default:
              setFormError(true)
              setFormMessageError("Hubo un error al iniciar sesión")
              setLoad(false)
          }
        } catch (error) {
          setFormError(true)
          setFormMessageError("Hubo un error al iniciar sesión")
          setTimeout(() => {
            setFormError(false)
            setFormMessageError("Debes diligenciar todos los campos")
          }, 3000)
          setLoad(false)
        }
      }
    }
  }

  //useEffect
  React.useEffect(() => {
    ValidationInputUsername(userdata)
  }, [ValidationInputUsername, userdata])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        overflowX: 'hidden',
        overflowY: 'hidden',
        // backgroundColor: 'red',
        position: 'absolute',
        display: 'flex'
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: '100%', md: '67%', lg: '67%' },
          height: '100hv',
          backgroundColor: '#DEDEDE',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Card
          sx={{
            width: { xs: '100%', sm: '80%', md: '60%', lg: '60%', xl: '50%' },
            boxShadow: 3
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Avatar
                src='/asset/logo_tokomarket_lightblue.png'
                alt='Tokomarket logo lightblue'
                sx={{
                  width: 100,
                  height: 100,
                  marginTop: 3
                }}
              />
              <Typography
                sx={{
                  fontSize: 24,
                  marginBottom: 5
                }}
              >Tokomarket</Typography>

              <Typography
                sx={{
                  fontSize: 16,
                  marginBottom: 2,
                  color: formError ? "#FE0000" : "#111111"
                }}
              >
                {formMessageError}
              </Typography>

            </Box>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <FormControl sx={{ width: '100%', marginBottom: 3 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-username">Usuario o correo electrónico</InputLabel>
                  <OutlinedInput
                    sx={{
                      border: usernameError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                    }}
                    onChange={(e) => inputChangeHandler(e)}
                    id="outlined-adornment-username"
                    type={'text'}
                    name={'username'}
                    endAdornment={
                      <InputAdornment position="end">
                        <SupervisedUserCircleIcon />
                      </InputAdornment>
                    }
                    label="Usuario o correo electrónico"
                  />
                  <FormHelperText
                    id="component-helper-text"
                    sx={{
                      color: usernameError ? "#FE0000" : "#111111"
                    }}
                  >
                    {usernameMessageError}
                  </FormHelperText>
                </FormControl>

                <FormControl sx={{ width: '100%', marginBottom: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                  <OutlinedInput
                    sx={{
                      border: passwordError ? "1px solid #FE0000" : "1px solid #DEDEDE"
                    }}
                    id="outlined-adornment-password"
                    onChange={(e) => inputChangeHandler(e)}
                    type={'password'}
                    name={'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <PasswordIcon />
                      </InputAdornment>
                    }
                    label="Contraseña"
                  />
                  <FormHelperText
                    id="component-helper-text"
                    sx={{
                      color: passwordError ? "#FE0000" : "#111111"
                    }}
                  >
                    {passwordMessageError}
                  </FormHelperText>
                </FormControl>

                <Typography
                  sx={{
                    marginBottom: 3
                  }}
                >¿Olvidaste tu contraseña?</Typography>

                <LoadingButton
                  loading={load}
                  loadingPosition="start"
                  type='submit'
                  startIcon={<ExitToAppIcon />}
                  variant="outlined"
                >
                  Iniciar sesión
                </LoadingButton>

              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          width: '33%',
          height: '100hv',
          display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
          justifyContent: 'center',
          backgroundColor: '#304fba',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: 3
        }}
      >
        <Box>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 44,
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              lineHeight: 1,
              marginBottom: 4,
              color: '#dddddd'
            }}
          >
            Hola!, Amigo o Emprendimiento
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              marginBottom: 2,
              color: '#dddddd'
            }}
          >
            Bienvenidos a Tokomarket
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 20,
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#dddddd'
            }}
          >
            Indica los datos de usuario o correo electrónico y contraseña para acceder a nuestra plataforma.
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 20,
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              marginY: 3,
              color: '#dddddd'
            }}
          >
            ó
          </Typography>
          <Typography
            onClick={() => navigate('/register')}
            sx={{
              textAlign: 'center',
              fontSize: 20,
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#dddddd',
              cursor: 'pointer'
            }}
          >
            Haz click aquí para registrarte.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Login