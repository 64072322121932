import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import ProdAndServCategories from './ProdAndServCategories'
import Features from './Features'
import ProductsAndServices from './PoductsAndServices'

const FindProductsAndServices = () => {
    return (
        <Paper sx={{ 
            my: 2,
            pb:2,
        }}>
            <Typography
                sx={{
                    fontSize: 22,
                    p: 2
                }}
                variant='h3'
            >Productos y Servicios</Typography>
            <Box
                sx={{
                    display:'flex',
                    flexDirection: {xs:'column', sm:'column', md:'row'}
                }}
            >
                <Box
                    sx={{
                        width:{xs:'100%', sm:'100%', md:'30%', lg:'22%', xl:'22%'}
                    }}
                >
                    <ProdAndServCategories/>
                </Box>
                <Box
                    sx={{
                        width:{xs:'100%', sm:'100%', md:'28%', lg:'28%', xl:'28%'},
                    }}
                >
                    <Features/>
                </Box>
                <Box
                    sx={{
                        border:'1px solid #DEDEDE',
                        mb:1,
                        mt:{xs:1, sm:1}
                    }}
                ></Box>
                <Box
                    sx={{
                        width:{xs:'100%', sm:'100%', md:'48%', lg:'48%', xl:'48%'},
                    }}
                >
                    <ProductsAndServices/>
                </Box>
            </Box>
        </Paper>
    )
}

export default FindProductsAndServices