import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { toast } from "react-toastify";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const ProductBySizes = ({ StaticProductDetaildata, detailsProducts, setDetailsProducts }) => {
    // states
    const [detailProductsData, setDetailProductData] = React.useState(StaticProductDetaildata)

    // Functions

    const addDetailProducts = () => {
        if (detailProductsData.productQty !== "" && detailProductsData.productSize !== "") {
            setDetailsProducts(detalProduct => {
                return [
                    ...detalProduct,
                    detailProductsData
                ]
            });
            setDetailProductData(StaticProductDetaildata);
        } else {
            toast.error("Faltan valores para agregar un detalle", 3000)
            return;
        }
    }

    const removeDetailProducts = (index) => {
        const newDetailProducts = detailsProducts.filter((dprdo, i) => i !== index);
        setDetailsProducts(newDetailProducts)
    }

    return (
        <Box>

            <Box sx={{ width: '100%', mt: 2 }}>
                <Typography
                    variant='h5'
                    sx={{
                        fontSize: 18,
                        color: '#745856',
                        mb: 1,
                        pl: 1
                    }}
                >Agregar fotos y detalles según el tipo</Typography>
                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
            </Box>


            <Box>
                <TableContainer>
                    <Table sx={{ minWidth: 550 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Cantidad</TableCell>
                                <TableCell>Tamaño</TableCell>
                                <TableCell>Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                detailsProducts.length > 0 && detailsProducts?.map((detail, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{detail.productQty}</TableCell>
                                        <TableCell>{detail.productSize}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => removeDetailProducts(index)} aria-label="remove" sx={{ color: theme => theme.palette.danger.main[500] }}>
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            sx={{
                                fontSize: 18,
                                color: '#745856',
                                mt: 2,
                                pl: 1
                            }}
                        >Agregar características al producto</Typography>
                        <TextField
                            id="product-quantinty"
                            size="medium"
                            label="Cantidad de productos"
                            name={'productQty'}
                            value={detailProductsData.productQty}
                            helperText="Introduce la cantidad de productos"
                            sx={{ width: '100%', mt: 2 }}
                            onChange={
                                (e) => setDetailProductData((product) => {
                                    return {
                                        ...product,
                                        productQty: e.target.value
                                    }
                                })

                            }
                        />

                        <TextField
                            id="product-size"
                            size="medium"
                            label="Tamaño producto"
                            name={'productSize'}
                            value={detailProductsData.productSize}
                            helperText="Introduce el tamaño del producto"
                            sx={{ width: '100%', mt: 2 }}
                            onChange={
                                (e) => setDetailProductData((product) => {
                                    return {
                                        ...product,
                                        productSize: e.target.value.toUpperCase()
                                    }
                                })

                            }
                        />

                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" onClick={() => addDetailProducts()}>Agregar Detalle</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ProductBySizes