import React from 'react'
import { Paper, Typography, Box, Button } from '@mui/material'

const UserList = () => {
  return (
    <Paper sx={{ pt: 2, mt: 3, pb:2 }}>
        <Box sx={{display:'flex', justifyContent: 'space-between'}}>
            <Typography sx={{ fontSize: '1.5rem', ml: 2, mb: 3 }}>User List</Typography>
            <Button variant="contained" size="medium" sx={{ mr: 2, height: '2.5rem' }}>ADD USER</Button>
        </Box>
    </Paper>
  )
}

export default UserList