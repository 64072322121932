import config from "../settings/config"

const GET_INFO_ACCOUNT_URL_API = `${config.urlServer}/api/account/get-account-info`
const UPDATE_INFO_ACCOUNT_URL_API = `${config.urlServer}/api/account/update-account-info`
const DELETE_ACCOUNT_URL_API = `${config.urlServer}/api/account/delete-account`
const GET_USER_ACTIVITY_URL_API = `${config.urlServer}/api/user-activity/get-user-activity`
const CHANGE_PASSWORD_USER_URL_API = `${config.urlServer}/api/auth/change-password`
const UPDATE_ACCOUNT_BILLING_INFORMATION = `${config.urlServer}/api/billing-informarion/create-billing-info`
const GET_ACCOUNT_BILLING_INFORMATION = `${config.urlServer}/api/billing-informarion/get-billing-info`
const UPDATED_ACCOUNT_TERMS_FROM_COMPANY = `${config.urlServer}/api/account/update-terms`

const AccountService = {}

AccountService.getInfoAccount = async(token) => {
    if(token){
        const getAccount = await fetch(GET_INFO_ACCOUNT_URL_API,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (!getAccount.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al traer los datos de la cuenta'
            }
            return response
        }
        let data = await getAccount.json()
        let response = {
            code: '200',
            account: data.account
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.updateInfoAccount = async (token, infoAccount) => {
    if(token){
        // console.log(infoAccount)
        const updateAccount = await fetch(UPDATE_INFO_ACCOUNT_URL_API,{
            method:'PUT',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(infoAccount)
        })
        if (!updateAccount.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al eliminar la cuenta'
            }
            return response
        }
        let response = {
            code: '200',
            account: await updateAccount.json()
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.deleteAccount = async (token, infoAccount) => {
    if(token){
        const deleteAccount = await fetch(DELETE_ACCOUNT_URL_API,{
            method:'DELETE',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(infoAccount)
        })
        if (!deleteAccount.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al traer los datos de la cuenta'
            }
            return response
        }
        let response = {
            code: '200',
            account: await deleteAccount.json()
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.getUserActivity = async (token) => {
    if(token){
        const getUserActivity = await fetch(GET_USER_ACTIVITY_URL_API,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        // console.log(getUserActivity)
        if (!getUserActivity.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al traer los datos de las actividades de usuario'
            }
            return response
        }
        const data = await getUserActivity.json()
        let response = {
            code: data.code,
            userActivity: data.userActivity 
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.changePassword = async (token, password)=> {
    if(token){
        const updatePasswordAccount = await fetch(CHANGE_PASSWORD_USER_URL_API,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify({password:password})
        })
        if (!updatePasswordAccount.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al cambiar la contraseña'
            }
            return response
        }
        let data = await updatePasswordAccount.json()
        let response = {
            code: '200',
            account: data
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.updateBillingInformation = async (token, accountBillingInfo) => {
    if(token){
        const updateBillingInformation = await fetch(UPDATE_ACCOUNT_BILLING_INFORMATION,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(accountBillingInfo)
        })
        if (!updateBillingInformation.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al actualizar los datos de facturación'
            }
            return response
        }
        let data = await updateBillingInformation.json()
        let response = {
            code: '200',
            account: data.billingInfo
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.getBillingInformation = async(token) => {
    if(token){
        const getBillingInformation = await fetch(GET_ACCOUNT_BILLING_INFORMATION,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (!getBillingInformation.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al traer los datos de la cuenta'
            }
            return response
        }
        let data = await getBillingInformation.json()
        let response = {
            code: '200',
            billinfInfo: data.billinfInfo
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

AccountService.aceptTermsFromCompanyAccount = async (token, terms) => {
    if(token){
        const updatedAccountTerms = await fetch(UPDATED_ACCOUNT_TERMS_FROM_COMPANY,{
            method:'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify({terms:terms})
        })
        if (!updatedAccountTerms.ok) {
            let response = {
                code: '500',
                msg: 'Hubo un error al actualizar los terminos y condiciones'
            }
            return response
        }
        let data = await updatedAccountTerms.json()
        let response = {
            code: '200',
            account: data.account
        }
        return response
    }else{
        let response = {
            code: '500',
            msg: 'Hubo un error con el token de usuario'
        }
        return response
    }
}

export default AccountService