import { Avatar, Box, Paper, Typography } from '@mui/material'
import React from 'react'

const LastVisit = () => {
    return (
        <Paper sx={{ m: 1, mt:2 }}>
            <Box>
                <Typography
                    sx={{ fontSize: 18, p: 2, color: '#745856', }}
                >
                    Ultimas visitas
                </Typography>
                <Box sx={{border:1, borderColor:'divider'}}></Box>
                <Box sx={{ p: 2, color: '#745856', }}>
                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', mb:2}}>
                        <Avatar
                            alt="La info del usurio" src="https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png"
                        />
                        <Typography variant='p' sx={{fontSize: 22, ml:2}}>Información de usuario 1</Typography>
                    </Box>

                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', mb:2}}>
                        <Avatar
                            alt="La info del usurio" src="https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png"
                        />
                        <Typography variant='p' sx={{fontSize: 22, ml:2}}>Información de usuario 2</Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', mb:2}}>
                        <Avatar
                            alt="La info del usurio" src="https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png"
                        />
                        <Typography variant='p' sx={{fontSize: 22, ml:2}}>Información de usuario 3</Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', mb:2}}>
                        <Avatar
                            alt="La info del usurio" src="https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png"
                        />
                        <Typography variant='p' sx={{fontSize: 22, ml:2}}>Información de usuario 4</Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', mb:2}}>
                        <Avatar
                            alt="La info del usurio" src="https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png"
                        />
                        <Typography variant='p' sx={{fontSize: 22, ml:2}}>Información de usuario 5</Typography>
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', mb:2}}>
                        <Avatar
                            alt="La info del usurio" src="https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png"
                        />
                        <Typography variant='p' sx={{fontSize: 22, ml:2}}>Información de usuario 6</Typography>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default LastVisit