import React from 'react'
import { Box,Dialog, DialogContent, DialogTitle, Typography, Alert } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const JoinUsModal = ({ setOpenJoinModal, openJoinUsModal, setJoinStepsModal }) => {
  const [load, setLoad] = React.useState(false)

  //Functions
  const handleClose = () => {
    setOpenJoinModal(false);
  }

  const handleSumbit = () => {
      setLoad(true)
      setTimeout(() => {
        setLoad(false)
        handleClose()
        setJoinStepsModal(true)
      },3000)
  }

  return (
    <>
      <Dialog
        open={openJoinUsModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-delete-modal"
        aria-describedby="alert-dialog-delete-modal"
      >
        <DialogTitle id="alert-dialog-delete-modal-title">
          {"Registro de comerciante"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ px: 2, pb: 2 }}>
            <Typography>Inscríbete como comerciante para ofrecer tus productos o servicios.</Typography>
          </Box>
          <Box sx={{ px: 2 }}>
            <Typography variant='h3' sx={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center', mb: 3 }}>Nos alegra que hayas tomado esta desición.</Typography>
          </Box>
          <Box>
            <Alert severity="warning">
              <Typography sx={{ fontWeight: 'bold' }}>Para tener en cuenta</Typography>
              <Typography>Vas a registrarte como comerciante y cambiaras tu estatus de Usuario a Comerciante, estás seguro que quieres iniciar el proceso?</Typography>
            </Alert>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
            <LoadingButton
              loading={load}
              onClick={() => handleSumbit()}
              loadingPosition="start"
              type='button'
              startIcon={<PlayCircleOutlineIcon />}
              variant="contained"
            >
              INICIAR PROCESO
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default JoinUsModal