import RootRouter from './components/routes/Index';
import SelectThemeModeContext from './hooks/SelectThemeModeContext';
import CssBaseline from '@mui/material/CssBaseline';
import AuthProvider from './hooks/AuthProvider';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <SelectThemeModeContext>
      <AuthProvider>
        <CssBaseline />
        <RootRouter />
      </AuthProvider>
      <ToastContainer/>
    </SelectThemeModeContext>
  );
}

export default App;
