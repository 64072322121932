import React from 'react'
import PublicLayout from '../../layouts/publicLayout/PublicLayout'
import { Box } from '@mui/material'
import Interests from './Interests'
import SeasonProducts from './SeasonProducts'
import FindProductsAndServices from './FindProductsAndServices'
import Footer from '../../custom/Footer'
import JoinUs from './JoinUs'

const Bazaar = () => {
  return (
    <PublicLayout>
      <Box
        sx={{
          width: '100%',
          backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.default.main[100] : theme.palette.background.default,
          display: 'flex',
          justifyContent:'center',
          alignItems:'center',
          flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'column', xl: 'column' }
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }
          }}
        >
          <SeasonProducts />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }
          }}
        >
          <Interests />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }
          }}
        >
          <FindProductsAndServices />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }
          }}
        > 
          <JoinUs/>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }
          }}
        > 
          <Footer/>
        </Box>
      </Box>
    </PublicLayout>
  )
}

export default Bazaar