import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from 'react'
import { SelectThemeContext } from '../../../hooks/SelectThemeModeContext'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const appBarWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: appBarWidth,
        width: `calc(100% - ${appBarWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MainAppBar = props => {
    const contextValue = useContext(SelectThemeContext)

    const toggleDrawerHandler = () => {
        props.onToggleDrawer();
    }

    return (
        <AppBar position="absolute" open={props.open}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                    width: '100%'
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawerHandler}
                    sx={{
                        marginRight: '36px',
                        ...(props.open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Tokomarket
                </Typography>
                <IconButton sx={{ ml: 1, color: 'gray' }} onClick={contextValue.colorMode.toggleColorMode} color="inherit">
                    {contextValue.theme.palette.mode === 'dark' ? <Brightness7Icon sx={{color:(theme) => theme.palette.secondary.main}}/> : <Brightness4Icon sx={{color:(theme) => theme.palette.secondary.main}}/>}
                </IconButton>
                <IconButton onClick={props.onLogout}>
                    <LogoutIcon color={'secondary'} />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default MainAppBar;