const regExpNumber = /^\d{6,15}$/
const regExpCellphone = /^\d{10}$/
// const regExpAlfanumerica = /^[a-zA-Z0-9!$%&?¡¿\s]+$/
const regExpAlfanumericaLimit25 = /^(?=[\s\S]{1,25}$)[a-zA-Z0-9_\s]+$/
const regExpEmail = /^[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9*+/={|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g

function validateValuesForms(typeValue, value) {
    switch (typeValue) {
        case "alf25":
            if (!value.match(regExpAlfanumericaLimit25)) {
                // console.log(value.match(regExpAlfanumericaLimit25))
                return false
            } else {
                // console.log(value.match(regExpAlfanumericaLimit25))
                return true
            }

        case "document":
            // console.log("document ",value)
            if (!value.match(regExpNumber)) {
                // console.log(value.match(regExpNumber))
                return false
            } else {
                // console.log(value.match(regExpNumber))
                return true
            }

        case "cellphone":
            // console.log("cellphone ",value)
            if (!value.match(regExpCellphone)) {
                // console.log(value.match(regExpCellphone))
                return false
            } else {
                // console.log(value.match(regExpCellphone))
                return true
            }

        case "email":
            // console.log("cellphone ",value)
            if (!value.match(regExpEmail)) {
                // console.log(value.match(regExpEmail))
                return false
            } else {
                // console.log(value.match(regExpEmail))
                return true
            }
        
            default:
                return false

    }
}

export default validateValuesForms