import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from "react-toastify";

//Icons
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PublishIcon from '@mui/icons-material/Publish';

import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';

//Services
import CompanyService from '../../../services/CompanyService'
import ChangeCompanyLogo from './Modals/ChangeCompanyLogo';
import ChangeCompanyBanner from './Modals/ChangeCompanyBanner';

const styles = {
    width: '100%',
    position: 'absolute',
    top: '-500px'
}

const Header = ({ initialData, openUpdateCompanyInfo }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [companyData, setCompanyData] = React.useState(initialData)
    const [companyDataError, setCompanyDataError] = React.useState(null)

    const [openUploadCompanyLogo, setOpenUploadCompanyLogo] = React.useState(false)
    const [openUploadCompanyBanner, setOpenUploadCompanyBanner] = React.useState(false)

    // functions
    const fecthCompanyAllInfo = React.useCallback(async () => {
        setLoad(true)
        try {
            let fecthCompanyAllInfo = await CompanyService.fetchCompanyAllInfo(token)
            setCompanyData(fecthCompanyAllInfo.company)
            // console.log(fecthCompanyAllInfo)
            setLoad(false)
            return;
        } catch (error) {
            console.log(error)
            setCompanyDataError("Hubo un error al traer la información")
            setLoad(false)
            setTimeout(() => {
                setCompanyDataError(null)
            }, 10000)
            return;
        }
    }, [token])

    const publishHandlerCommerce = async () => {
        if (token) {
            setLoading(true)
            try {
                await CompanyService.publishCompany(token, companyData._id)
                toast.success('La compñía se ha actualizado')
                setLoading(false)
                fecthCompanyAllInfo()
                return;
            } catch (error) {
                console.log(error)
                setCompanyDataError("Hubo un error al actualizar el comercio")
                setLoading(false)
                setTimeout(() => {
                    setCompanyDataError(null)
                }, 10000)
                return;
            }
        } else {
            setLoading(false)
            toast.error('No hay usuario authenticado')
        }
    }

    //Effects
    React.useEffect(() => {
        if (!openUpdateCompanyInfo && !openUploadCompanyLogo && !openUploadCompanyBanner) {
            void fecthCompanyAllInfo()
        }
    }, [fecthCompanyAllInfo, openUpdateCompanyInfo, openUploadCompanyLogo, openUploadCompanyBanner])

    return (
        <Paper sx={{ width: '100%' }}>
            <Box
                onClick={() => setOpenUploadCompanyBanner(true)}
                sx={{
                    width: '100%',
                    height: "35vh",
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer'
                }}
            >
                {/* <img style={styles} alt={'compañia test imagen'} src={companyData.companyBanner} /> */}
                <Box 
                    component={'img'}
                    // style={styles}
                    alt={'compañia test imagen'}
                    src={companyData.companyBanner}
                    sx={{
                        width: '100%',
                        position: 'absolute',
                        top: {sm:"-100px",md:'-200px', lg:'-300px', xl:'-500px'}
                    }}    
                ></Box>
            </Box>
            <Box
                onClick={() => setOpenUploadCompanyLogo(true)}
                sx={{
                    width: {xs: '20vw',md: '10vw'},
                    height: "20vh",
                    overflow: 'hidden',
                    position: 'absolute',
                    backgroundColor: 'white',
                    padding: 1,
                    borderRadius: 2,
                    top: 210,
                    left: 30,
                    cursor: 'pointer',
                    zIndex: 10
                }}
            >
                <img style={{ width: '100%' }} alt={'compañia test imagen 2'} src={companyData.companyLogo} />
            </Box>
            {
                companyDataError && (
                    <Box
                        sx={{
                            p: 2
                        }}
                    >
                        <Typography
                            variant='p'
                            sx={{
                                color: '#FE0000',
                                fontWeight: 'bold'
                            }}
                        >{companyDataError}</Typography>
                    </Box>
                )
            }
            <Box
                sx={{ display: 'flex', width: '87%', ml: '13%' }}
            >
                {
                    load ? (
                        <>
                            <Box
                                sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Spinner />
                            </Box>
                        </>
                    ) :
                        (
                            <>
                                <Box sx={{ width: '50%' }}>
                                    <Typography
                                        sx={{ fontSize: 28, color: '#745856', mt: 3.5, ml: 3.5 }}
                                    >{companyData.companyName}</Typography>
                                    <Stack direction="row" sx={{ mb: 3, mt: 1, ml: 3.3 }}>
                                        <Box sx={{ width: '33.3%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <AssignmentIndIcon sx={{ width: 30, height: 30, color: 'gray', mr: 1 }} />
                                            <Typography variant='h5' sx={{ fontSize: 22, color: 'gray' }}>Sector 1</Typography>
                                        </Box>
                                        <Box sx={{ width: '33.3%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <AssignmentIndIcon sx={{ width: 30, height: 30, color: 'gray', mr: 1 }} />
                                            <Typography variant='h5' sx={{ fontSize: 22, color: 'gray' }}>Sector 1</Typography>
                                        </Box>
                                        <Box sx={{ width: '33.3%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <AssignmentIndIcon sx={{ width: 30, height: 30, color: 'gray', mr: 1 }} />
                                            <Typography variant='h5' sx={{ fontSize: 22, color: 'gray' }}>Sector 1</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                                    <LoadingButton
                                        sx={{ mr: 4 }}
                                        loading={loading}
                                        onClick={() => publishHandlerCommerce()}
                                        loadingPosition="start"
                                        type='submit'
                                        startIcon={<PublishIcon />}
                                        variant="contained"
                                    >
                                        {companyData.companyStatus === 'private' ? "PUBLICAR" : "OCULTAR"}
                                    </LoadingButton>
                                           
                                </Box>
                            </>
                        )
                }

            </Box>
            <ChangeCompanyLogo openUploadCompanyLogo={openUploadCompanyLogo} setOpenUploadCompanyLogo={setOpenUploadCompanyLogo} companyDataInfo={companyData} />
            <ChangeCompanyBanner openUploadCompanyBanner={openUploadCompanyBanner} setOpenUploadCompanyBanner={setOpenUploadCompanyBanner} companyDataInfo={companyData} />
        </Paper>
    )
}

export default Header