function addDotThousands(numero) {
    // Convertir el número a una cadena de texto
    let numeroString = numero.toString();
  
    // Separar la parte entera de la parte decimal (si existe)
    let partes = numeroString.split('.');
    let parteEntera = partes[0];
    let parteDecimal = partes.length > 1 ? ',' + partes[1] : '';
  
    // Agregar los puntos de mil a la parte entera
    parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    // Devolver el número con los puntos de mil agregados
    return parteEntera + parteDecimal;
}

export default addDotThousands