import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { toast } from "react-toastify";

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';

const ProductByColors = ({ StaticProductDetaildata, detailsProducts, setDetailsProducts }) => {
    // states

    const [color, setColor] = React.useState("#000000")
    const [detailProductsData, setDetailProductData] = React.useState(StaticProductDetaildata)

    // Functions

    const addColorToStackColorJandler = () => {
        if (color !== "") {
            setDetailProductData((product) => {
                return {
                    ...product,
                    productColor: [...detailProductsData.productColor, color]
                }
            })
        }
    }

    const removeColorToStackColorJandler = (index) => {
        const newColors = detailProductsData.productColor?.filter((ref, i) => i !== index);
        setDetailProductData(product => {
            return {
                ...product,
                productColor: newColors
            }
        });
    }

    const addDetailProducts = () => {
        if (detailProductsData.productQty !== "" && detailProductsData.productColor.length > 0) {
            setDetailsProducts(detalProduct => {
                return [
                    ...detalProduct,
                    detailProductsData
                ]
            });
            setDetailProductData(StaticProductDetaildata);
            setColor('#000000')
        } else {
            toast.error("Faltan valores para agregar un detalle", 3000)
            return;
        }
    }

    const removeDetailProducts = (index) => {
        const newDetailProducts = detailsProducts.filter((dprdo, i) => i !== index);
        setDetailsProducts(newDetailProducts)
    }

    return (
        <Box>

            <Box sx={{ width: '100%', mt: 2 }}>
                <Typography
                    variant='h5'
                    sx={{
                        fontSize: 18,
                        color: '#745856',
                        mb: 1,
                        pl: 1
                    }}
                >Agregar fotos y detalles según el tipo</Typography>
                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
            </Box>


            <Box>
                <TableContainer>
                    <Table sx={{ minWidth: 550 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Cantidad</TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                detailsProducts.length > 0 && detailsProducts?.map((detail, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{detail.productQty}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                {
                                                    detail?.productColor.map((color, i) => (
                                                        <Box key={i} sx={{ width: 20, height: 20, backgroundColor: color, borderRadius: '50%' }}></Box>
                                                    ))
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => removeDetailProducts(index)} aria-label="remove" sx={{ color: theme => theme.palette.danger.main[500] }}>
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            sx={{
                                fontSize: 18,
                                color: '#745856',
                                mt: 2,
                                pl: 1
                            }}
                        >Agregar características al producto</Typography>
                        <TextField
                            id="product-quantinty"
                            size="medium"
                            label="Cantidad de productos"
                            name={'productQty'}
                            value={detailProductsData.productQty}
                            helperText="Introduce la cantidad de productos"
                            sx={{ width: '100%', mt: 2 }}
                            onChange={
                                (e) => setDetailProductData((product) => {
                                    return {
                                        ...product,
                                        productQty: e.target.value
                                    }
                                })

                            }
                        />
                        <Stack direction="row" spacing={2}>
                            {
                                detailProductsData.productColor.map((color, i) => (
                                    <Box key={i} sx={{ width: 40, height: 40, backgroundColor: color, borderRadius: '50%', position: 'relative' }}>
                                        <IconButton onClick={() => removeColorToStackColorJandler(i)} aria-label="Remove" sx={{ mt: 1, position: 'absolute', left: 25, top: -18 }} color='primary'>
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                    </Box>
                                ))
                            }
                        </Stack>
                        <Box display={'flex'}>
                            <TextField
                                id="product-color"
                                size="medium"
                                type='color'
                                label="Color del producto"
                                helperText="Indicanos el color de tu producto"
                                sx={{ width: '90%', mt: 2 }}
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                            />
                            <Box sx={{ width: '10%', mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                                <IconButton onClick={() => addColorToStackColorJandler()} aria-label="Add" sx={{ mt: 1 }} color='primary'>
                                    <AddIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" onClick={() => addDetailProducts()}>Agregar Detalle</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default ProductByColors