import React from 'react'
import { Button, Card, Typography, Box } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DashboardIcon from '@mui/icons-material/Dashboard';
import addDotThousands from '../../../utils/addDotThousands';

const CommerceItem = () => {
    return (
        <Card
            sx={{
                width: { xs: "80vw", sm: "20vw", md: "20vw", lg: "20vw" },
                boxShadow: "0px 0px 20px 4px #ADADAD",
                borderRadius: 3,
                position: "relative",
                margin: "2vw",
            }}
        >
            <Box
                sx={{
                    color: "#ADADAD",
                    width: { xs: "10vw", sm: "3.5vw", md: "3.5vw", lg: "3.5vw" },
                    height: { xs: "10vw", sm: "3.5vw", md: "3.5vw", lg: "3.5vw" },
                    fontSize: { xs: "3.5vw", sm: "0.9vw", md: "0.9vw", lg: "0.9vw" },
                    position: "absolute",
                    right: { xs: "1vw", sm: "0.5vw", md: "0.5vw", lg: "0.5vw" },
                    top: { xs: "1vw", sm: "0.5vw", md: "0.5vw", lg: "0.5vw" },
                    backgroundColor: "white",
                    boxShadow: "10px 10px 69px -5px rgba(0,0,0,0.75)",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                }}
            >
                <DeleteForeverIcon
                    sx={{
                        width: { xs: "5vw", sm: "1.3vw", md: "1.3vw", lg: "1.3vw" },
                        height: { xs: "5vw", sm: "1.3vw", md: "1.3vw", lg: "1.3vw" },
                    }}
                />
            </Box>
            <Box
                sx={{
                    backgroundImage: `url(https://res.cloudinary.com/dujeh3ms8/image/upload/v1681831803/tokomarket_uploads_commerce/ynexrjp7xtl3tnmy7tle.jpg)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: { xs: "70vw", sm: "20vw", md: "20vw", lg: "20vw" },
                    borderRadius: "0 0 8rem 8rem",
                    borderBottom: "solid 2px #ADADAD",
                }}
            />
            <Box
                component="div"
                sx={{
                    width: "100%",
                    paddingLeft: "1vw",
                    paddingTop: "1vw",
                    height: { xs: "25vw", sm: "5vw", md: "5vw", lg: "5vw" },
                    // border: "solid 1px transparent",
                    // borderLeft: "solid 1px #ADADAD",
                    // borderRight: "solid 1px #ADADAD",
                    marginTop: "0.5vw",
                }}
            >
                <Typography
                    variant="h3"
                    paragraph={true}
                    noWrap={true}
                    sx={{
                        fontSize: { xs: "4vw", sm: "1.05vw", md: "1.05vw", lg: "1.05vw" },
                        color: "#745856",
                        textAlign: "center",
                    }}
                >
                    {'Product Name kjshfk hjsdhfkd uhdfkdjfh hfjdhsjkfh jhsdkfjh'}
                </Typography>
                <Typography
                    component="p"
                    sx={{
                        color: "#ADADAD",
                        fontSize: { xs: "3.5vw", sm: "1vw", md: "1vw", lg: "1vw" },
                        marginTop: { xs: "-3vw", sm: "-0.5vw", md: "-0.5vw", lg: "-0.5vw" },
                        textAlign: "center",
                    }}
                >
                    {'Product description daiha jhfkjh jhke jhewkjh jhkfdj jhdkfjdshf hkjsdhfk hjhjk jghkdfg jhjksdhfjs jhjsdkfhkj'}
                </Typography>
            </Box>
            <Box
                component="div"
                sx={{
                    width: "100%",
                    height: "5vw",
                }}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
            >
                <Typography
                    borderRadius={1}
                    sx={{
                        position: "absolute",
                        top: { xs: "2vw", sm: "1.3vw", md: "1.3vw", lg: "1.3vw" },
                        left: { xs: "-1vw", sm: "-1.2vw", md: "-1.2vw", lg: "-1.2vw" },
                        bgcolor: "white",
                        boxShadow: "10px 10px 69px -5px rgba(0,0,0,0.75)",
                        color: "#ADADAD",
                        width: { xs: "30vw", sm: "8vw", md: "8vw", lg: "8vw" },
                        height: { xs: "8vw", sm: "2vw", md: "2vw", lg: "2vw" },
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    fontSize={{ xs: "3.5vw", sm: "1vw", md: "1vw", lg: "1vw" }}
                    ml={"2vw"}
                >
                    ${addDotThousands(1202130000)}
                </Typography>
                <Box
                    component="div"
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        zIndex: 10,
                    }}
                >
                    <Button
                        sx={{
                            color: "#ADADAD",
                            width: "50%",
                            height: { xs: "8vw", sm: "3vw", md: "3vw", lg: "3vw" },
                            fontSize: { xs: "3.5vw", sm: "0.9vw", md: "0.9vw", lg: "0.9vw" },
                        }}
                        endIcon={
                            <DashboardIcon
                                sx={{
                                    width: { xs: "5vw", sm: "1.3vw", md: "1.3vw", lg: "1.3vw" },
                                    height: { xs: "5vw", sm: "1.3vw", md: "1.3vw", lg: "1.3vw" },
                                }}
                            />
                        }
                    >
                        Detalles
                    </Button>
                </Box>
                <Box
                    sx={{
                        backgroundImage: "url(/assets/CardFade.svg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: { xs: "30vw", sm: "15vw", md: "15vw", lg: "15vw" },
                        position: "absolute",
                        bottom: 0,
                        zIndex: 8,
                    }}
                />
            </Box>
        </Card>
    )
}

export default CommerceItem