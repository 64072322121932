import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

import ProductFeatureItem from './ProductFeatureItem';

const Features = () => {
    return (
        <>
            <Swiper
                style={{ width: '100%', height: '90vh', paddingRight:20 }}
                slidesPerView={4}
                grabCursor={true}
                direction={"vertical"}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
                <SwiperSlide>
                    <ProductFeatureItem />
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default Features