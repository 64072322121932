import React from 'react'
import { toast } from "react-toastify";
import { Box, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText, TextField, Grid, Autocomplete } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import StoreIcon from '@mui/icons-material/Store';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import HouseIcon from '@mui/icons-material/House';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import colombiaStates from '../../../../assets/country/Colombia.json'

//Serivces
import AccountService from '../../../../services/AccountService'

const UpdateBillingAddress = ({
    openUpdateBillingAddress,
    setOpenUpdateBillingAddress,
    billingInformation,
    countries,
    depColombia,
    token
}) => {
    //RegExp
    let validNumber = /^\b\d{10}\b/g
    let validEmail = /^[A-Za-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[A-Za-z0-9*+/={|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/g

    //state
    const [newBillingInformation, setNewBillingInformation] = React.useState(billingInformation)
    const [country, setCountry] = React.useState("")
    const [load, setLoad] = React.useState(false)
    const [states, setStates] = React.useState([])
    const [cities, setCities] = React.useState([])
    //Functions
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoad(true)
        let isValidNumber = newBillingInformation.contact.match(validNumber)
        let isValidEmail = newBillingInformation.billingEmail.match(validEmail)

        if (isValidNumber === null) {
            toast.error(`Debes especificar un número celular válido`, { autoClose: 5000 })
            setLoad(false)
        } else if (isValidEmail === null) {
            toast.error(`Debes especificar un correo electrónico válido`, { autoClose: 5000 })
            setLoad(false)
        } else if (
            newBillingInformation.companyName === "" ||
            newBillingInformation.country === "" ||
            newBillingInformation.state === "" ||
            newBillingInformation.city === "" ||
            newBillingInformation.address === "" ||
            newBillingInformation.zip === ""
        ) {
            toast.error(`Debes diligenciar los campos obligatorios`, { autoClose: 5000 })
            setLoad(false)
        } else {
            try {
                await AccountService.updateBillingInformation(token, newBillingInformation)
                toast.success("Se han actualizado los datos correctamente", 2000)
                setOpenUpdateBillingAddress(false)
                setLoad(false)
            } catch (error) {
                setLoad(false)
                toast.error("Hubo un error al actualizar la información", { autoClose: 4000 })
            }
        }
    }

const handleClose = () => {
    setOpenUpdateBillingAddress(!openUpdateBillingAddress)
}

const inputChangeHandler = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setNewBillingInformation(newBillingInformation => {
        return {
            ...newBillingInformation,
            [name]: value
        }
    })
}

const countrySelected = (country) => {
    if (!country) {

        return;
    } else {
        let countrySelected = countries.find((c) => c.value === country)
        if (countrySelected) {
            setCountry(countrySelected.value)
        }
    }
}

React.useEffect(() => {
    const setStatesColombia = () => {
        setStates(depColombia.STATES)
    }
    setStatesColombia()
}, [depColombia.STATES])

React.useEffect(() => {
    const extractCityData = () => {
        if(newBillingInformation.state !== ""){
            let selectedStateInfo = colombiaStates[newBillingInformation.state]
            setCities(selectedStateInfo)
        }else{
            setCities([])
        }
    }

    extractCityData()
}, [newBillingInformation.state])

return (
    <>
        <Dialog
            open={openUpdateBillingAddress}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle id="alert-dialog-title">
                {"Información de facturación"}
            </DialogTitle>
            <DialogContent >
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="companyNameAccount">Nombre de la compañía *</InputLabel>
                                <OutlinedInput
                                    id="companyNameAccount"
                                    type={'text'}
                                    name={'companyName'}
                                    value={`${newBillingInformation.companyName}`}
                                    onChange={(e) => inputChangeHandler(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <StoreIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Nombre de la compañía *"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Escribe el nombre del titular</FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="contactAccount">Contacto *</InputLabel>
                                <OutlinedInput
                                    id="contactAccount"
                                    autoComplete='none'
                                    type={'text'}
                                    name={'contact'}
                                    value={`${newBillingInformation.contact}`}
                                    onChange={(e) => inputChangeHandler(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <PhoneIphoneIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contacto *"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Indica el teléfono del contacto</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="contactAccount">Correo Electrónico para facturación *</InputLabel>
                                <OutlinedInput
                                    id="billingEmail"
                                    autoComplete='none'
                                    type={'text'}
                                    name={'billingEmail'}
                                    value={`${newBillingInformation.billingEmail}`}
                                    onChange={(e) => inputChangeHandler(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <EmailIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Correo Electrónico para facturación *"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Indica el correo electrónico de facturación</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    fullWidth
                                    name={'country'}
                                    value={newBillingInformation.country ? newBillingInformation.country : ""}
                                    // onChange={(e) => inputChangeHandler(e)}
                                    inputValue={country}
                                    onInputChange={(event, newInputValueCountry) => {
                                        countrySelected(newInputValueCountry)
                                        setNewBillingInformation({ ...newBillingInformation, country: newInputValueCountry })
                                    }}
                                    id="combo-box-contry"
                                    options={countries || []}
                                    renderInput={(params) => <TextField {...params} label="País *" />}
                                />
                                <FormHelperText id="outlined-weight-helper-text">Indica el país de residencia</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    fullWidth
                                    name={'state'}
                                    value={newBillingInformation.state}
                                    inputValue={newBillingInformation.state}
                                    onInputChange={(event, newInputValueState) => {
                                        setNewBillingInformation({ ...newBillingInformation, state: newInputValueState })
                                    }}
                                    id="combo-box-state"
                                    options={states || []}
                                    renderInput={(params) => <TextField {...params} label="Estado *" />}
                                />
                                <FormHelperText id="combo-box-state-helper-text">Indica el estado de residencia</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    fullWidth
                                    name={'city'}
                                    value={newBillingInformation.city}
                                    inputValue={newBillingInformation.city}
                                    onInputChange={(event, newInputValueStateCity) => {
                                        setNewBillingInformation({ ...newBillingInformation, city: newInputValueStateCity })
                                    }}
                                    id="combo-box-state"
                                    options={cities || []}
                                    renderInput={(params) => <TextField {...params} label="Ciudad *" />}
                                />
                                <FormHelperText id="combo-box-state-helper-text">Indica la ciudad de residencia</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="billingAddress">Dirección de facturación *</InputLabel>
                                <OutlinedInput
                                    id="billingAddress"
                                    type={'text'}
                                    name={'address'}
                                    value={`${newBillingInformation.address}`}
                                    onChange={(e) => inputChangeHandler(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <HouseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Dirección de facturación *"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Indica la dirección de facturación</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="billingAddress">Código Postal *</InputLabel>
                                <OutlinedInput
                                    id="billingAddress"
                                    type={'text'}
                                    name={'zip'}
                                    value={`${newBillingInformation.zip}`}
                                    onChange={(e) => inputChangeHandler(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <MarkunreadMailboxIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Código Postal *"
                                />
                                <FormHelperText id="outlined-weight-helper-text">Indica el código postal de la dirección</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <LoadingButton
                                loading={load}
                                loadingPosition="start"
                                type='submit'
                                startIcon={<SystemUpdateAltIcon />}
                                variant="contained"
                            >
                                ACTUALIZAR
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    </>
)
}

export default UpdateBillingAddress