import { Box, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';

//Icons
import EditIcon from '@mui/icons-material/Edit';

//Services
import CompanyService from '../../../services/CompanyService'
import UpdateCompanyInfo from '../company/Modals/UpdateCompanyInfo'


const AboutCompany = ({ initialData, openUpdateCompanyInfo, setOpenUpdateCompanyInfo }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [companyData, setCompanyData] = React.useState(initialData)
    const [companyDataError, setCompanyDataError] = React.useState(null)

    // functions

    const fecthCompanyAllInfo = React.useCallback(async () => {
        setLoad(true)
        try {
            let fecthCompanyAllInfo = await CompanyService.fetchCompanyAllInfo(token)
            setCompanyData(fecthCompanyAllInfo.company)
            // console.log(fecthCompanyAllInfo)
            setLoad(false)
            return;
        } catch (error) {
            console.log(error)
            setCompanyDataError("Hubo un error al traer la información")
            setLoad(false)
            setTimeout(() => {
                setCompanyDataError(null)
            }, 10000)
            return;
        }
    }, [token])

    //Effects
    React.useEffect(() => {
        if(!openUpdateCompanyInfo){
            void fecthCompanyAllInfo()
        }
    }, [fecthCompanyAllInfo, openUpdateCompanyInfo])

    return (
        <Paper
            sx={{ m: 1 }}
        >
            <Box>
                <Box
                    sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center'
                    }}
                >
                    <Typography
                        variant='h5'
                        sx={{
                            fontSize: 18,
                            color: '#745856',
                            p: 2
                        }}
                    >Acerca de</Typography>
                    <IconButton sx={{width:45, height:45, mr:1}} onClick={() => setOpenUpdateCompanyInfo(true)} color="primary" aria-label="Edit Company Info">
                        <EditIcon />
                    </IconButton>
                </Box>
                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                {
                    companyDataError && (
                        <Box
                            sx={{
                                p: 2
                            }}
                        >
                            <Typography
                                variant='p'
                                sx={{
                                    color: '#FE0000',
                                    fontWeight: 'bold'
                                }}
                            >{companyDataError}</Typography>
                        </Box>
                    )
                }
                {
                    load ? (
                        <>
                            <Box
                                sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Spinner />
                            </Box>
                        </>
                    )
                        :
                        (
                            <>
                                <Box
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Nombre del comercio:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyName}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Tipo:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyType}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Estado:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyStatus}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Ubicación:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyCountry}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Contacto:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyContact}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Correo:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyEmail}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Tipo Productos:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyProductType}</Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    variant='h5'
                                    sx={{
                                        fontSize: 18,
                                        color: '#745856',
                                        p: 2
                                    }}
                                >Seguridad de la compañía</Typography>
                                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                                <Box
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Puntaje:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyScore}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Verificación de correo:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyVerifyEmail ? 'Si' : 'No'}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Verificación de contacto:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyVerifyContact ? 'Si' : 'No'}</Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    variant='h5'
                                    sx={{
                                        fontSize: 18,
                                        color: '#745856',
                                        p: 2
                                    }}
                                >Información de interés</Typography>
                                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                                <Box
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Visitas totales:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyTotalView}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Ventas realizadas:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyTotalSale}</Typography>
                                    </Box>
                                </Box>
                            </>
                        )
                }
            </Box>

            <UpdateCompanyInfo
                openUpdateCompanyInfo={openUpdateCompanyInfo}
                setOpenUpdateCompanyInfo={setOpenUpdateCompanyInfo}
                companyDataInfo={companyData}
            />
        </Paper>
    )
}

export default AboutCompany