import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';

//Services
import CompanyService from '../../../services/CompanyService'

const SocialCompanyInfo = ({ initialData }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [companyData, setCompanyData] = React.useState(initialData)
    const [companyDataError, setCompanyDataError] = React.useState(null)

    // functions

    const fecthCompanyAllInfo = React.useCallback(async () => {
        setLoad(true)
        try {
            let fecthCompanyAllInfo = await CompanyService.fetchCompanyAllInfo(token)
            setCompanyData(fecthCompanyAllInfo.company)
            // console.log(fecthCompanyAllInfo)
            setLoad(false)
            return;
        } catch (error) {
            console.log(error)
            setCompanyDataError("Hubo un error al traer la información")
            setLoad(false)
            setTimeout(() => {
                setCompanyDataError(null)
            }, 10000)
            return;
        }
    }, [token])

    //Effects
    React.useEffect(() => {
        void fecthCompanyAllInfo()
    }, [fecthCompanyAllInfo])

    return (
        <Paper
            sx={{ m: 1 }}
        >
            <Box>
                <Typography
                    variant='h5'
                    sx={{
                        fontSize: 18,
                        color: '#745856',
                        p: 2
                    }}
                >Información Social Comercio</Typography>
                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                {
                    companyDataError && (
                        <Box
                            sx={{
                                p: 2
                            }}
                        >
                            <Typography
                                variant='p'
                                sx={{
                                    color: '#FE0000',
                                    fontWeight: 'bold'
                                }}
                            >{companyDataError}</Typography>
                        </Box>
                    )
                }
                {
                    load ? (
                        <>
                            <Box
                                sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Spinner />
                            </Box>
                        </>
                    ) :
                        (
                            <>
                                <Box
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Plan Actual:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >free</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Permite publicación:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >No</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Publicaciones permitidas:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >Ninguna</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Publicaciones realizadas:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >8</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Likes en publicaciones:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.socialLikes}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Dislikes en publicaciones:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.socialDislikes}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Interacciones totales en publicaciones:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >15</Typography>
                                    </Box>
                                </Box>

                                <Typography
                                    variant='h5'
                                    sx={{
                                        fontSize: 18,
                                        color: '#745856',
                                        p: 2
                                    }}
                                >Comentarios y calificación</Typography>
                                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                                <Box
                                    sx={{
                                        p: 2
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Comentarios obtenidos:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyTotalComments}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                fontWeight: 'bold'
                                            }}
                                        >Calificación media:</Typography>
                                        <Typography
                                            variant='p'
                                            sx={{
                                                color: '#745856',
                                                ml: 1
                                            }}
                                        >{companyData.companyUserScore}</Typography>
                                    </Box>
                                </Box>
                            </>
                        )
                }
            </Box>
        </Paper>
    )
}

export default SocialCompanyInfo