import React from 'react'
import { createContext, useState, useMemo } from "react"
import { Paper, Typography, Box, Button, Alert } from '@mui/material'

const GlobalNotificationProvider = ({ children }) => {
    const [hide, setHide] = useState(false)

    const hideNoty = useMemo(
        () => ({
            handleChangeNoty: () => {
                setHide((prevMode) => (prevMode === false ? true : false));
            }
        }),
        [],
    );

    const contextValue = {
        hide,
        setHide,
        hideNoty
    }
    return <GlobalNotificationContext.Provider value={contextValue}>
        {
            !hide && (
                <Paper sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '1.2rem', ml: 2, mb: 1, mt: 2 }}>Notificación</Typography>
                        <Button onClick={() => contextValue.hideNoty.handleChangeNoty()} variant="contained" sx={{ mr: 2, height: '2rem', width: '1rem', mt: 1 }}>X</Button>
                    </Box>
                    <Box>
                        <Alert severity="warning">
                            <Typography sx={{ fontWeight: 'bold' }}>Notification Title</Typography>
                            <Typography>Text Notification</Typography>
                        </Alert>
                    </Box>
                </Paper>
            )
        }
        {children}
    </GlobalNotificationContext.Provider>
}

export const GlobalNotificationContext = createContext()
export default GlobalNotificationProvider