import { Avatar, Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { convertDate } from '../../../helpers/utils'

import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';

//Services
import CompanyService from '../../../services/CompanyService'

const CompanyOwner = ({ initialData }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [companyData, setCompanyData] = React.useState(initialData)
    const [companyDataError, setCompanyDataError] = React.useState(null)

    // functions

    const fecthCompanyAllInfo = React.useCallback(async () => {
        setLoad(true)
        try {
            let fecthCompanyAllInfo = await CompanyService.fetchCompanyAllInfo(token)
            setCompanyData(fecthCompanyAllInfo.company)
            // console.log(fecthCompanyAllInfo)
            setLoad(false)
            return;
        } catch (error) {
            console.log(error)
            setCompanyDataError("Hubo un error al traer la información")
            setLoad(false)
            setTimeout(() => {
                setCompanyDataError(null)
            }, 10000)
            return;
        }
    }, [token])

    //Effects
    React.useEffect(() => {
        void fecthCompanyAllInfo()
    }, [fecthCompanyAllInfo])

    return (
        <Paper
            sx={{ m: 1, mt:2 }}
        >
            <Box>
                <Typography
                    variant='h5'
                    sx={{
                        fontSize: 18,
                        color: '#745856',
                        p: 2
                    }}
                >Propietario de la Compañía</Typography>
                <Box sx={{ border: 1, borderColor: 'divider' }}></Box>
                {
                    companyDataError && (
                        <Box
                            sx={{
                                p: 2
                            }}
                        >
                            <Typography
                                variant='p'
                                sx={{
                                    color: '#FE0000',
                                    fontWeight: 'bold'
                                }}
                            >{companyDataError}</Typography>
                        </Box>
                    )
                }
                {
                    load ? (
                        <>
                            <Box
                                sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Spinner />
                            </Box>
                        </>
                    ) : (
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    my: 3
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: 120,
                                        height: 120
                                    }}
                                    alt={companyData.companyOwner?.firstName} src={companyData.companyOwner?.pic_profile}
                                />
                            </Box>
                            <Box
                                sx={{
                                    p: 2
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#745856',
                                        textAlign:'center',
                                        fontWeight: 'bold',
                                    }}
                                >{companyData.companyOwner?.firstName} {companyData.companyOwner?.lastName}</Typography>
                                <Typography
                                    sx={{
                                        color: '#745856',
                                        textAlign:'center'
                                    }}
                                >
                                    Creado el {convertDate(companyData.createdAt)}
                                </Typography>
                            </Box>
                        </Box>
                    )
                }
            </Box>
        </Paper>
    )
}

export default CompanyOwner