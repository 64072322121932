import React from 'react'
import { Box, Paper, Typography, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const columns = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
        field: 'sem',
        headerName: '#Sem',
        width: 70,
        editable: false,
    },
    {
        field: 'code',
        headerName: 'Código',
        width: 90,
        editable: false,
    },
    {
        field: 'reference',
        headerName: 'Referencia',
        width: 150,
        editable: false,
    },
    {
        field: 'qty',
        headerName: 'Cantidad',
        type: 'number',
        width: 90,
        editable: false,
    },
    {
        field: "action",
        headerName: "Action",
        sortable: false,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking
    
            const api = params.api;
            const thisRow = {};
    
            api
              .getAllColumns()
              .filter((c) => c.field !== "__check__" && !!c)
              .forEach(
                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
              );
    
            return alert(JSON.stringify(thisRow, null, 4));
          };
    
          return <Button onClick={onClick}>Click</Button>;
        }
    },
]

const UserPurchase = ({ load }) => {
    const [pageSize, setPageSize] = React.useState(5);

    const data = [
        { id: 1, sem: 1, code: 2323, reference: "hsjdhakj12", qty: 34, actions: <div>QA</div> }
    ]

    return (
        <Paper
            sx={{
                pt:2
            }}
        >
            <Typography
                sx={{
                    fontSize: '1.5rem',
                    ml:2,
                    mb:3
                }}
            >
                Compras realizadas
            </Typography>
            <Box style={{ height: 400, width: '100%' }}>
                <DataGrid
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    rows={data}
                    columns={columns}
                    loading={load}
                    // components={{ Toolbar: GridToolbar }}
                    // disableSelectionOnClick
                    // experimentalFeatures={{ newEditingApi: true }}
                />
            </Box>
        </Paper>
    )
}

export default UserPurchase