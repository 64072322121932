import { Box, Button, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import CommerceItem from './CommerceItem';
import AddIcon from '@mui/icons-material/Add';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';

import useAuth from '../../../hooks/useAuth';
import Spinner from '../../custom/Spinner';
import AddProductToCommerce from './Modals/AddProductToCommerce';

//Services
import CompanyService from '../../../services/CompanyService'


const Commerce = ({ initialData }) => {
    const { token } = useAuth()
    const [load, setLoad] = React.useState(false)
    const [companyData, setCompanyData] = React.useState(initialData)
    const [companyDataError, setCompanyDataError] = React.useState(null)

    const [openAddNewProduct, setOpenAddNewProduct] = React.useState(false)

    // functions

    const fecthCompanyAllInfo = React.useCallback(async () => {
        setLoad(true)
        try {
            let fecthCompanyAllInfo = await CompanyService.fetchCompanyAllInfo(token)
            setCompanyData(fecthCompanyAllInfo.company)
            // console.log(fecthCompanyAllInfo)
            setLoad(false)
            return;
        } catch (error) {
            console.log(error)
            setCompanyDataError("Hubo un error al traer la información")
            setLoad(false)
            setTimeout(() => {
                setCompanyDataError(null)
            }, 10000)
            return;
        }
    }, [token])

    //Effects
    React.useEffect(() => {
        void fecthCompanyAllInfo()
    }, [fecthCompanyAllInfo])

    return (
        <>

            <Paper sx={{ py: 2, mb: 2 }}>
                <Box>
                    {
                        companyDataError && (
                            <Box
                                sx={{
                                    p: 2
                                }}
                            >
                                <Typography
                                    variant='p'
                                    sx={{
                                        color: '#FE0000',
                                        fontWeight: 'bold'
                                    }}
                                >{companyDataError}</Typography>
                            </Box>
                        )
                    }
                    {

                        load ? (
                            <>
                                <Box
                                    sx={{ height: '15vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <Spinner />
                                </Box>
                            </>
                        ) :
                            (
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <Typography
                                        variant='h5'
                                        sx={{
                                            fontSize: 18,
                                            color: '#745856',
                                            p: 2
                                        }}
                                    >Puntos de visualización</Typography>
                                    <TextField
                                        id="viewerPoints"
                                        disabled
                                        value={companyData.companyViewPoints}
                                        label="Puntos de visualización"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocalPlayIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        size='small'
                                        variant="outlined"
                                        sx={{ mr: 2 }}
                                    />
                                </Box>
                            )
                    }
                </Box>
            </Paper>

            <Paper>
                <Box>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                fontSize: 18,
                                color: '#745856',
                                p: 2
                            }}
                        >Productos y Servicios</Typography>
                        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <Button onClick={() => setOpenAddNewProduct(true)} sx={{ height: '70%', mr: 2 }} variant="contained" endIcon={<AddIcon />}>Producto</Button>
                            <Button sx={{ height: '70%' }} variant="contained" endIcon={<AddIcon />}>Servicio</Button>
                        </Stack>
                    </Box>

                    <Box sx={{ border: 1, borderColor: 'divider' }}></Box>

                    <Stack
                        direction="row"
                        useflexgap="true"
                        flexWrap="wrap"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            maxHeight: '70vh',
                            overflowY: 'scroll'
                        }}
                    >
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />
                        <CommerceItem />

                    </Stack>
                </Box>
            </Paper>
            <AddProductToCommerce
                openAddNewProduct={openAddNewProduct}
                setOpenAddNewProduct={setOpenAddNewProduct}
                companyDataInfo={companyData}
            />
        </>
    )
}

export default Commerce