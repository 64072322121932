import config from '../settings/config'

const SIGNUP_USER_API_URL = `${config.urlServer}/api/auth/signup`
const RegisterService = {}

RegisterService.signUp = async(userData) => {
    if(userData){
        let responseUser = await fetch(SIGNUP_USER_API_URL, {
            method: 'POST',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(userData)
        })
        .then(response => response.json())
        return responseUser
    }else{
        let response = {
            error: "Error500",
            msg: "Hubo un error al registrarse"
        }
        return response
    }
}

export default RegisterService